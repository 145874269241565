import { InternalTenant, Tenant, TenantProfile } from 'entities/tenant';
import { TenantUser, TenantUserRole, RoleName } from 'types/user';
import ApiClient from 'remote/api-client';
import { TenantResponse, TenantSummaryResponse } from './tenants';

export interface SearchTenantResponse {
  tenants: InternalTenant[];
  hasHitLimit: Boolean;
}

export interface InternalTenantSummaryResponse extends TenantSummaryResponse {
  InternalName: string;
  IsolationIds: string[];
  SuspensionReason: string;
  MfaRequired: boolean;
  SsoConfigIds: string[];
  SalesforceCppId: string;
  HasUnpaidInvoices: boolean;
  StripeCustomerUrl: string;
  NamespaceProfile: TenantProfile;
}

const transformSummary = (response: InternalTenantSummaryResponse): InternalTenant => {
  return {
    id: response.NamespaceId,
    friendlyName: response.FriendlyName,
    internalName: response.InternalName,
    tenantType: response.NamespaceType,
    planType: response.PlanType,
    isolationIds: response.IsolationIds,
    suspended: response.Suspended,
    suspensionReason: response.SuspensionReason,
    mfaRequired: response.MfaRequired,
    ssoConfigIds: response.SsoConfigIds,
    salesforceCppId: response.SalesforceCppId,
    hasUnpaidInvoices: response.HasUnpaidInvoices,
    stripeUrl: response.StripeCustomerUrl,
    tenantProfile: response.NamespaceProfile,
    billingMethod: response.BillingMethod,
  };
};

const transformDetails = (response: TenantResponse): Tenant => {
  let tenant: Tenant = {
    id: response.NamespaceId,
    name: response.FriendlyName,
    tenantType: response.NamespaceType,
    planType: response.PlanType,
    suspended: response.Suspended,
    allowFreeDatabaseCreation: response.AllowFreeDatabaseCreation,
    availableNeo4jVersions: response.AvailableNeo4jVersions,
    providerConfigs: response.ProviderConfigs,
    tierConfigs: response.TierConfigs,
    unavailableTiers: response.UnavailableTiers,
    googleProjectId: response.GoogleProjectId,
    capabilities: response.Capabilities,
    hasUnpaidInvoices: response.HasUnpaidInvoices,
    hasBilling: response.HasBilling,
    billingMethod: response.BillingMethod,
    requiresBilling: response.RequiresBilling,
    tenantProfile: response.NamespaceProfile,
    availableActions: response.AvailableActions,
    notifications: response.Notifications,
    availableRoles: response.AvailableRoles,
  };

  if (response.OrganizationId) {
    tenant.organizationId = response.OrganizationId;
  }

  return tenant;
};

const get = async (tenantId: string): Promise<Tenant> =>
  ApiClient.get(`/tenants/${tenantId}`)
    .ignore([403, 404])
    .promise()
    .then(transformDetails);

// FIXME: a lot of this stuff should be internal tenant operations

const search = async (internalNamePrefix: string): Promise<SearchTenantResponse> =>
  ApiClient.get(`/internal/tenants?internal_name=${internalNamePrefix}&paginate=true`)
    .promise()
    .then(res => {
      return {
        tenants: res.Data.map(transformSummary),
        hasHitLimit: res.HasHitLimit,
      };
    });

// TODO: move to ./internal-tenant.ts
const searchByIsolationId = async (isolationId: string): Promise<SearchTenantResponse> =>
  ApiClient.get(`/internal/tenants?isolation_id=${isolationId}&paginate=true`)
    .promise()
    .then(res => {
      return {
        tenants: res.Data.map(transformSummary),
        hasHitLimit: res.HasHitLimit,
      };
    });

const getMembers = (tenantId: string): Promise<TenantUser[]> =>
  ApiClient.get(`/tenants/${tenantId}/members`).promise();

const getMemberRoles = (tenantId: string): Promise<TenantUserRole[]> =>
  ApiClient.get(`/tenants/${tenantId}/roles`)
    .ignoreAllErrors()
    .promise();

const updateMemberRoles = (
  tenantId: string,
  targetUserId: string,
  roles: RoleName[]
): Promise<TenantUser> =>
  ApiClient.patch(`/tenants/${tenantId}/members/${targetUserId}`)
    .issue({ roles })
    .ignoreAllErrors()
    .promise();

const editTenant = (tenantId: string, payload: { friendlyName: string }) =>
  ApiClient.patch(`/tenants/${tenantId}`)
    .issue({ FriendlyName: payload.friendlyName })
    .ignoreAllErrors()
    .promise();

const removeMember = (tenantMember: TenantUser) =>
  ApiClient.delete(`/tenants/${tenantMember.NamespaceId}/members/${tenantMember.UserId}`)
    .ignoreAllErrors()
    .promise();

const updateIsolationIds = (tenantId: string, isolationIds: string[]) =>
  ApiClient.post(`/tenants/${tenantId}/isolation-ids`)
    .issue({ IsolationIds: isolationIds })
    .ignore([403, 404, 422])
    .promise();

const linkSsoConfigId = (tenantId: string, ssoConfigId: string) =>
  ApiClient.put(`/tenants/${tenantId}/sso-config`)
    .issue({ SsoConfigId: ssoConfigId })
    .ignoreAllErrors()
    .promise();

const unlinkSsoConfigId = (tenantId: string, ssoConfigId: string) =>
  ApiClient.delete(`/tenants/${tenantId}/sso-config`)
    .issue({ SsoConfigId: ssoConfigId })
    .ignoreAllErrors()
    .promise();

export const setSuspension = (tenantId: string, suspended: boolean, reason?: string) =>
  ApiClient.put(`/tenants/${tenantId}/suspension`)
    .issue({ Suspended: suspended, Reason: reason })
    .ignoreAllErrors()
    .promise()
    .then(transformSummary);

export default {
  updateMemberRoles,
  editTenant,
  get,
  getMemberRoles,
  search,
  searchByIsolationId,
  getMembers,
  removeMember,
  transformDetails,
  updateIsolationIds,
  setSuspension,
  linkSsoConfigId,
  unlinkSsoConfigId,
};
