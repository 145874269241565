import React, { useMemo, useState } from 'react';
import { Database, Region, Tier } from 'entities/database';
import { getProductFromTier, Tenant } from 'entities/tenant';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { TrafficConfig, TrafficEnablement } from 'types/traffic-configs';
import TrafficConfigResources from 'remote/resources/traffic-configs';
import { validateYup, Validation } from 'utils/validation';
import * as yup from 'yup';
import { productFriendlyName } from 'types/product';
import {
  Alert,
  Button,
  Dialog,
  FormInput,
  FormSelect,
  LoadingSpinner,
  TextLink,
} from 'components/foundation';
import Icon from 'components/ui/icons';
import { useNotify } from 'state/notifications';
import { CopyInput } from 'components/application/copy-input';
import { ConfirmCheckbox, PrivateConnection, VpnMessage } from './shared';
import { getRegionsForTier } from 'utils/tiers-and-regions';
import { useTracking } from 'react-tracking';

interface FormData {
  tier?: Tier;
  region?: string;
  privateTraffic: TrafficEnablement;
  publicTraffic: TrafficEnablement;
  projectIds: string[];
}

const schema = yup.object({
  tier: yup
    .string()
    .oneOf(Object.values(Tier))
    .required()
    .label('Product'),
  region: yup
    .string()
    .required()
    .label('Region'),
  privateTraffic: yup
    .string()
    .oneOf(Object.values(TrafficEnablement))
    .required(),
  publicTraffic: yup
    .string()
    .oneOf(Object.values(TrafficEnablement))
    .required(),
  projectIds: yup
    .array()
    .min(1)
    .required()
    .of(
      yup
        .string()
        .min(6)
        .max(30)
        .matches(
          /^[a-z][a-z0-9-]+[a-z0-9]$/,
          'GCP Project ID must be 6 to 30 lowercase letters, digits, or hyphens. It must start with a letter and not end with a hyphen.'
        ) // https://cloud.google.com/resource-manager/reference/rest/v1/projects
        .label('GCP Project ID')
    ),
});

const validate = (data: FormData): Validation<FormData> => {
  return validateYup(schema, data);
};

const defaults = (trafficConfig?: TrafficConfig): FormData => ({
  tier: trafficConfig?.tier,
  region: trafficConfig?.region,
  projectIds: trafficConfig ? trafficConfig.gcpProperties.projectIds : [''],
  privateTraffic: trafficConfig?.privateTraffic ?? TrafficEnablement.ENABLED,
  publicTraffic: trafficConfig?.publicTraffic ?? TrafficEnablement.ENABLED,
});

/**
 * This dialog is quite complicated, so to try to simplify things
 * a bit, it has no `open` prop. The consumer should just unmount
 * and remount it. This helps make managing state easier
 * as there is no chance of bleeding state between dialog open/closes
 */
export const GCPTrafficConfigDialog = ({
  tenant,
  trafficConfig,
  title,
  onClose,
  onSuccess,
  databases,
  // Only needed in create mode
  // Used to prepopulate the props (e.g. ProjectIds)
  // when creating
  existingTrafficConfigs = [],
  // Only needed in create mode
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {},
}: {
  tenant: Tenant;
  trafficConfig?: TrafficConfig;
  title: string;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<Tier, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
  databases: Database[];
}) => {
  const notify = useNotify();
  const providerConfigs = tenant.providerConfigs;
  const editMode = !!trafficConfig;
  const [data, setData] = useState<FormData>(() => defaults(trafficConfig));
  const defaultErrorHandler = useDefaultErrorHandler();
  const [validation, setValidation] = useState<Validation<FormData>>({});
  const [enableError, setEnableError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const tracking = useTracking();
  const affectedDatabases = useMemo(() => {
    let dbs: Database[] = [];
    if (data.region && data.tier && data.publicTraffic === TrafficEnablement.DISABLED) {
      dbs = databases.filter(db => db.Region === data.region && db.Tier === data.tier);
    }
    return dbs;
  }, [data.publicTraffic]);

  const handleClose = () => {
    onClose();
  };

  const handleFinishLater = () => {
    tracking.trackEvent({
      action: 'new_network_access_finish_later',
      properties: { event_label: 'clicked' },
    });
    onClose();
  };

  const handleTierChange = option => {
    tracking.trackEvent({
      action: 'new_network_access_product_select',
      properties: { event_label: 'selected', tier: option.value },
    });
    setData(prev => ({ ...prev, tier: option.value, region: undefined }));
  };
  const handleRegionChange = option => {
    const newRegion = option.value;
    tracking.trackEvent({
      action: 'new_network_access_region_select',
      properties: { event_label: 'selected', region: newRegion },
    });
    setData(prev => {
      const existingTrafficConfig = existingTrafficConfigs.find(
        c => c.tier === prev.tier && c.region === newRegion
      );
      // Existing traffic config may be undefined
      // if for example in dev environments you use the same
      // isolation id for enterprisedb and enterpriseds
      const existingProjectIds = existingTrafficConfig?.gcpProperties?.projectIds ?? [];
      return {
        ...prev,
        region: newRegion,
        projectIds: [...existingProjectIds, ''],
      };
    });
  };

  const handleProjectIdChange = (e, index) => {
    const newValue = e.target.value;
    setData(prev => {
      prev.projectIds[index] = newValue;
      const newProjectIds = [...prev.projectIds];
      return { ...prev, projectIds: newProjectIds };
    });
  };
  const handleDeleteProjectId = index => {
    setData(prev => ({ ...prev, projectIds: data.projectIds.filter((_, i) => i !== index) }));
  };
  const handleAddProjectId = () => {
    setData(prev => ({ ...prev, projectIds: [...prev.projectIds, ''] }));
  };

  const handleConfirmChange = e => {
    tracking.trackEvent({
      action: 'vpn_statement_acknowledgement',
      properties: { event_label: 'checked' },
    });
    setConfirm(e.target.checked);
  };

  const handlePublicTrafficDisabledChange = e => {
    const newValue = !e.target.checked;
    tracking.trackEvent({
      action: newValue ? 'enable_public_traffic' : 'disable_public_traffic',
      properties: { event_label: 'checked' },
    });
    setData(prev => ({
      ...prev,
      publicTraffic: newValue ? TrafficEnablement.ENABLED : TrafficEnablement.DISABLED,
    }));
  };

  const handleNextStep = () => setStep(Math.min(step + 1, 3));
  const handlePreviousStep = () => setStep(Math.max(step - 1, 1));

  const handleSubmit = async (e, close = false) => {
    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }

    setValidation({});
    setLoading(true);

    try {
      const response = await TrafficConfigResources.update(tenant.id, data.tier, data.region, {
        privateTraffic: data.privateTraffic,
        publicTraffic: data.publicTraffic,
        gcpProperties: { projectIds: data.projectIds },
      });
      tracking.trackEvent({
        action: 'new_network_access_enable_private_link',
        properties: { event_label: 'clicked' },
      });
      handleNextStep();
      if (onSuccess) onSuccess(response);
      if (close) handleClose();
    } catch (err) {
      if (err.response.status === 422) {
        setEnableError(err.response.responseMessage);
      } else if (err.response.status <= 499 && err.response.status >= 400) {
        notify.error('Failed to update network security configuration');
      } else {
        defaultErrorHandler(err);
      }
    }

    setLoading(false);
  };

  const handleSubmitAndClose = e => handleSubmit(e, true);

  const handleTestGuidesClick = () => {
    tracking.trackEvent({ action: 'link_to_connection_test_guides' });
  };

  const trackCopyEndpoint = () => {
    tracking.trackEvent({ action: 'copy_gcp_endpoint_service_name' });
  };

  const tiers = [...new Set(existingTrafficConfigs.map(config => config.tier))];
  let regions: Region[] | null = null;
  let regionOptions = [];
  if (data.tier) {
    regions = getRegionsForTier(providerConfigs, data.tier);
    const alreadyConfiguredRegions = existingTierRegions[data.tier] ?? [];
    const regionsToConfigure = existingTrafficConfigs
      .filter(tc => tc.tier === data.tier)
      .map(tc => tc.region);
    regions = regions.filter(
      r => !alreadyConfiguredRegions.includes(r.name) && regionsToConfigure.includes(r.name)
    );
    regionOptions = regions.map(r => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }

  const tierOptions = tiers
    .filter(t => [Tier.ENTERPRISE, Tier.AURA_DSE].includes(t)) // Shouldn't happen in practice, but my devenv is whack
    .map(tier => {
      const product = getProductFromTier(tier);
      return {
        key: tier,
        value: tier,
        label: productFriendlyName(product),
      };
    });

  const serviceAttachmentUrl = trafficConfig?.status?.gcpStatus?.serviceAttachmentUrl;
  const configCreating = !trafficConfig || !serviceAttachmentUrl;

  return (
    <Dialog open onClose={handleClose} size="large">
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Content className="tw-flex tw-flex-col tw-gap-4">
        <p>Step {step} of 3</p>
        {step === 1 && (
          <>
            <FormSelect
              label="Product"
              options={tierOptions}
              value={data.tier}
              onChange={handleTierChange}
              disabled={editMode}
              errorText={validation.tier?.message}
              data-testid="gcp-dialog-select-product"
            />
            {regions && (
              <>
                {regionOptions.length === 0 && (
                  <Alert
                    type="warning"
                    title="All regions configured"
                    description={`All ${productFriendlyName(
                      getProductFromTier(data.tier)
                    )} regions have been configured.`}
                  />
                )}
                {regionOptions.length > 0 && (
                  <FormSelect
                    label="Region"
                    placeholder={
                      regionOptions.length === 0
                        ? 'Private service connect is already configured for all regions'
                        : undefined
                    }
                    helpText="GCP Private Service Connect applies to all instances in the region."
                    options={regionOptions}
                    value={data.region ?? ''}
                    onChange={handleRegionChange}
                    disabled={editMode}
                    errorText={validation.region?.message}
                    data-testid="gcp-dialog-select-region"
                  />
                )}
              </>
            )}
            {data.region && (
              <>
                {data.projectIds.map((projectId, index, arr) => {
                  // Disable the first N items where N is the number of items already existing
                  // in the project ids config
                  const disabled = (trafficConfig?.gcpProperties?.projectIds ?? []).length > index;
                  const validationKey = `projectIds[${index}]`;
                  const validationItem = validation[validationKey];
                  return (
                    <FormInput
                      fluid
                      data-testid="gcp-dialog-project-id"
                      key={index}
                      aria-label="Target GCP Project ID"
                      label={index === 0 ? "Target GCP Project ID's" : ''}
                      placeholder="Enter a GCP Project ID..."
                      value={projectId}
                      onChange={v => handleProjectIdChange(v, index)}
                      errorText={validationItem?.message}
                      helpText={
                        index === arr.length - 1
                          ? 'Your project ID will be listed next to the project name in parentheses as "Project ID: your-project-id"'
                          : undefined
                      }
                      disabled={disabled}
                      rightIcon={
                        index > 0 &&
                        !disabled && (
                          <Icon
                            name="TrashIconOutline"
                            onClick={() => handleDeleteProjectId(index)}
                            data-testid={`delete-project-id-button-${index + 1}`}
                          />
                        )
                      }
                    />
                  );
                })}
                <div>
                  <Button
                    fill="outlined"
                    onClick={handleAddProjectId}
                    iconName="PlusIconOutline"
                    data-testid="gcp-dialog-add-id"
                  >
                    Add project ID
                  </Button>
                </div>
                {enableError && <Alert description={enableError} type="danger" />}
              </>
            )}
          </>
        )}

        {step === 2 && (
          <>
            {configCreating && (
              <Alert
                title={
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <LoadingSpinner size="small" />
                    Configuring...
                  </div>
                }
                description="Private service connect is being configured..."
              />
            )}
            {!configCreating && (
              <>
                <Alert icon type="success" title="Accepted" />
                <CopyInput
                  id="copy-gcp-service-attachment-url"
                  label="Service Attachment URL"
                  value={serviceAttachmentUrl}
                  readOnly
                  onCopy={trackCopyEndpoint}
                />
              </>
            )}
            <h6>
              <span className="tw-font-normal">
                Create a Private Service Connect Endpoint and DNS Record:
              </span>
            </h6>
            <ul className="console-network-instructions tw-list-decimal tw-ml-8">
              <li>
                Log in to your Google Cloud Console:{' '}
                <TextLink externalLink href="https://console.cloud.google.com/">
                  https://console.cloud.google.com/
                </TextLink>
              </li>
              <li>
                Navigate to <b>Network services</b> by searching for it in the search bar or finding
                it in the side menu under the <b>Networking</b> section.
              </li>
              <li>
                Under the Network services side menu, click <b>Private Service Connect</b> and
                ensure you are on the <b>CONNECTED ENDPOINTS</b> tab.
              </li>
              <li>
                Create a Connected Endpoint:
                <ul className="tw-list-[lower-alpha] tw-ml-6">
                  <li>
                    Click <b>+ CONNECT ENDPOINT</b>.
                  </li>
                  <li>
                    Set <b>Target</b> to <b>Published Service.</b>
                  </li>
                  <li>
                    Set <b>Target Service</b> to <i>{serviceAttachmentUrl}</i>.
                  </li>
                  <li>
                    Set <b>Endpoint name</b> to an appropriate name.
                  </li>
                  <li>
                    Set <b>Network</b> to the VPC you want to connect to your Aura instances from.
                  </li>
                  <li>
                    Set <b>Subnetwork</b> to the subnet of the VPC you want to connect to your Aura
                    instance from.
                  </li>
                  <li>
                    Set <b>IP address</b> to an existing IP address. If you need a new IP address
                    you can create one from the <b>IP address</b> dropdown by selecting{' '}
                    <b>CREATE IP ADDRESS</b>, entering a <b>Name</b>, and clicking <b>Reserve</b>.
                  </li>
                  <li>
                    Click <b>ADD ENDPOINT</b>.
                  </li>
                </ul>
              </li>

              <li>
                Under the Network services side menu, click <b>Cloud DNS</b>.
              </li>
              <li>
                Create a Response Policy Zone:
                <ul className="tw-list-[lower-alpha] tw-ml-6">
                  <li>
                    Navigate to the <b>RESPONSE POLICY ZONES</b> tab.
                  </li>
                  <li>
                    Click <b>CREATE RESPONSE POLICY</b>.
                  </li>
                  <li>
                    Set <b>Name</b> and <b>Description</b> to appropriate values.
                  </li>
                  <li>
                    Set <b>Network</b> as the network of the Connected Endpoint.
                  </li>
                  <li>
                    Click <b>CREATE</b>.
                  </li>
                </ul>
              </li>
              <li>
                Add a Response Policy Zone rule:
                <ul className="tw-list-[lower-alpha] tw-ml-6">
                  <li>
                    Click <b>+ ADD RULE</b>
                  </li>
                  <li>
                    Set <b>Name</b> to an appropriate name.
                  </li>
                  <li>
                    Set <b>DNS Name</b> to{' '}
                    <code>*.production-orch-&lt;orchNumber&gt;.neo4j.io</code>.
                  </li>
                  <li>
                    Set <b>Action</b> to Local Data.
                  </li>
                  <li>
                    Click <b>ADD A RESOURCE RECORD SET</b>.
                    <ul>
                      <li>
                        Set <b>Resource Record Type</b> to A.
                      </li>
                      <li>
                        Set <b>IPv4 Address</b> to the IP addresses you had reserved for the
                        Connected Endpoint earlier.
                      </li>
                      <li>
                        Click <b>DONE</b>.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Click <b>CREATE</b>.
                  </li>
                </ul>
              </li>
            </ul>
            <div className="tw-mt-4">
              The configuration should now be complete. Once you have an Aura instance created you
              should be able to test the connection using{' '}
              <TextLink
                href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
                externalLink
                onClick={handleTestGuidesClick}
              >
                this guide.
              </TextLink>
            </div>
            <div className="n-body-medium tw-mt-4">
              *{' '}
              <TextLink
                href="https://cloud.google.com/vpc/docs/configure-private-service-connect-services"
                externalLink
              >
                <i>Private Service Connect documentation</i>
              </TextLink>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <PrivateConnection
              publicTraffic={data.publicTraffic}
              dnsDomain={trafficConfig?.status?.dnsDomain}
              onPublicTrafficDisabledChange={handlePublicTrafficDisabledChange}
              affectedDatabases={affectedDatabases}
            />
            <VpnMessage href="https://neo4j.com/docs/aura/platform/security/#_gcp_private_endpoints" />
            <ConfirmCheckbox isConfirmed={confirm} onConfirmChange={handleConfirmChange} />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions className="tw-justify-between">
        <Button onClick={handleFinishLater} fill="outlined" data-testid="gcp-dialog-later">
          Finish later
        </Button>
        {step === 1 && !editMode && (
          <Button
            onClick={handleSubmit}
            fill="filled"
            loading={loading}
            disabled={!data.region || !data.tier}
            data-testid="gcp-dialog-enable-connect"
          >
            Enable Private Service Connect
          </Button>
        )}
        {step === 1 && editMode && (
          <Button
            onClick={handleSubmit}
            fill="filled"
            loading={loading}
            data-testid="gcp-dialog-next"
          >
            Next
          </Button>
        )}
        {step === 2 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              fill="filled"
              disabled={!serviceAttachmentUrl}
              data-testid="gcp-dialog-next"
            >
              Next
            </Button>
          </div>
        )}
        {step === 3 && (
          <div className="tw-flex tw-gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleSubmitAndClose}
              fill="filled"
              loading={loading}
              disabled={!confirm}
              data-testid="gcp-dialog-save"
            >
              Save
            </Button>
          </div>
        )}
      </Dialog.Actions>
    </Dialog>
  );
};
