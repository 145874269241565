import React, { useState, useEffect, ReactElement } from 'react';
import {
  Page,
  Alert,
  Button,
  IconButton,
  TextLink,
  LoadingSpinner,
  Tabs,
  Link,
  Label,
} from 'foundation';
import ProjectsResource from 'remote/resources/projects';
import InviteResource from 'remote/resources/invites';
import NamespacesResource from 'remote/resources/namespaces';
import InternalTenantsResource from 'remote/resources/internal-tenant';
import {
  InternalTenantDetails,
  PlanType,
  tenantTypeDisplayName,
  tierDisplayName,
} from 'entities/tenant';
import { User, Action, RoleName } from 'types/user';
import { Invite, InviteStatus } from 'types/invite';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { usePermissions } from 'store/hooks';
import { SuspendTenantModal, ReinstateTenantModal } from './suspension-modals';
import { useModalState } from 'utils/use-modal-state';
import * as yup from 'yup';
import { FeatureToggleOverridesPane } from './feature-toggle-overrides-pane';
import EditIsolationIdsModal from 'application/edit-isolation-unit';
import { PermissionTip } from 'components/application/permission-tip';
import { TenantSsoConfigs } from './edit-tenant/sso/sso-table';
import { UserTab } from './tenants-users';
import { InviteTab } from './tenants-invites';
import { ViewTenantDatabases } from '../databases';
import { EditPricingPlanModal } from './edit-pricing-plan-modal';
import { format } from 'date-fns';
import { TearDownTenantModal } from './tear-down-modal';
import NamespaceActions from 'actions/namespace-actions';
import inviteActions from 'actions/invite-actions';

interface Props {
  tenantId: string;
}

enum TabId {
  Users = 'users',
  Invites = 'invites',
  SsoLogins = 'ssologins',
  FeatureToggles = 'featuretoggles',
  Databases = 'databases',
}

const getErrorMessage = (err: Error, tenant: string): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }

  switch (err.reason) {
    case 'db-not-deleted':
      return `${err.responseMessage}. Users with running databases cannot be migrated. Please advise the user to delete their running databases.`;
    case 'tenant-not-found':
    case 'namespace-not-found':
    case 'project-not-found':
      return `Tenant "${tenant}" does not exist`;
    default:
      return `Error: ${err.reason || 'unknown'}: ${err.responseMessage}`;
  }
};

const isUuid = val =>
  yup
    .string()
    .uuid()
    .isValidSync(val);

const formatIsolationUnitId = (isolationUnitId: string, internalTenant: InternalTenantDetails) => {
  const isolationUnit = internalTenant.isolationUnits?.find(
    unit => unit.legacy_id === isolationUnitId
  );
  if (!isolationUnit || internalTenant.missingIsolationUnitIds?.includes(isolationUnit.id)) {
    return (
      <>
        <code>{isolationUnitId}</code> <Label color="danger">MISSING</Label>
      </>
    );
  }
  return (
    <>
      <code>{isolationUnitId}</code>
      {isolationUnit?.cloud_provider && (
        <Label color="default" fill="outlined">
          {isolationUnit.cloud_provider.toUpperCase().replace('ZURE', 'zure')}
        </Label>
      )}
      {isolationUnit?.tiers.map(tier => {
        let formattedTier: string | ReactElement;
        try {
          formattedTier = tierDisplayName(internalTenant, tier);
        } catch (e) {
          formattedTier = <code style={{ textTransform: 'lowercase' }}>{tier}</code>;
        }
        return (
          <Label key={tier} color="info" fill="semi-filled">
            {formattedTier}
          </Label>
        );
      })}
    </>
  );
};

const UserTenantsPage = ({ tenantId }: Props) => {
  const [loading, setLoading] = useState(true);
  const [internalTenant, setInternalTenant] = useState<InternalTenantDetails | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [pendingInvites, setPendingInvites] = useState<Invite[]>([]);
  const [expiredInvites, setExpiredInvites] = useState<Invite[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [usersRole, setUsersRole] = useState<{ [key: string]: string }>({});
  const suspendModal = useModalState();
  const [suspensionReason, setSuspensionReason] = useState(null);
  const reinstateModal = useModalState();
  const editIsolationIdsModal = useModalState();
  const editPricingPlanModal = useModalState();
  const tearDownTenantModal = useModalState();

  const permission = usePermissions();
  const canInviteToTenant = permission.allow(Action.CREATE, `namespaces/${tenantId}/invites`);
  const canReadTenants = permission.allow(Action.READ, `internal/namespaces/${tenantId}`);
  const canSuspendTenant = permission.allow(Action.SUSPEND, `namespaces/${tenantId}`);
  const canEditTenant = permission.allow(Action.UPDATE, `namespaces/${tenantId}`);
  const canRevokeUserAccess = permission.allow(Action.DELETE, `namespaces/${tenantId}/members/*`);
  const canRevokeInvites = permission.allow(Action.DELETE, `namespaces/${tenantId}/invites/*`);
  const canTearDownTenant = canSuspendTenant && canRevokeUserAccess && canRevokeInvites;

  const fetchInternalTenant = async () => {
    const tenantDetails = await InternalTenantsResource.getTenant(tenantId);
    setInternalTenant(tenantDetails);
    return tenantDetails;
  };

  const fetchPendingInvites = async () => {
    const tenantPendingInvites: Invite[] = await InviteResource.getByTenant(
      tenantId,
      InviteStatus.PENDING
    );
    setPendingInvites(tenantPendingInvites);
  };

  const fetchExpiredInvites = async () => {
    const tenantExpiredInvites: Invite[] = await InviteResource.getByTenant(
      tenantId,
      InviteStatus.EXPIRED
    );
    setExpiredInvites(tenantExpiredInvites);
  };

  const fetchUsers = async () => {
    const usersInTenant: User[] = await ProjectsResource.findUsers(tenantId);
    const userTenantRoles = await NamespacesResource.getMemberRoles(tenantId);
    const rolesMap: { [key: string]: string } = userTenantRoles.reduce((current, role) => {
      const name =
        current[role.UserId] === RoleName.TENANT_ADMIN ? current[role.UserId] : role.Name;
      return {
        ...current,
        [role.UserId]: name,
      };
    }, {});
    setUsers(usersInTenant);
    setUsersRole(rolesMap);
  };

  const refetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      await fetchUsers();
    } catch (err) {
      setError(getErrorMessage(err, tenantId));
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (!isUuid(tenantId)) {
      setError(`Invalid tenant ID: '${tenantId}', expected a UUID value.`);
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await Promise.all([
        fetchInternalTenant(),
        fetchPendingInvites(),
        fetchExpiredInvites(),
        fetchUsers(),
      ]);
    } catch (err) {
      setError(getErrorMessage(err, tenantId));
    } finally {
      setLoading(false);
    }
  };

  const handleInviteUpdate = async () => {
    setLoading(true);
    setError(null);
    try {
      await fetchPendingInvites();
      await fetchExpiredInvites();
    } catch (err) {
      setError(getErrorMessage(err, tenantId));
    } finally {
      setLoading(false);
    }
  };

  const handleEditIsolationIDsSuccess = () => {
    fetchData();
    editIsolationIdsModal.close();
  };

  const handleEditPricingPlanSuccess = () => {
    fetchData();
    editPricingPlanModal.close();
  };

  useEffect(() => {
    if (canReadTenants) {
      fetchData();
    }
  }, [tenantId, canReadTenants]);

  const suspendTenant = async () => {
    suspendModal.setLoading(true);
    suspendModal.setError(null);
    try {
      const updatedTenant = await NamespacesResource.setSuspension(
        tenantId,
        true,
        suspensionReason
      );
      // TODO: ideally we should get an InternalTenantDetails object back
      // so we don't have to do this hack
      setInternalTenant({ ...internalTenant, ...updatedTenant });
      suspendModal.close();
    } catch (err) {
      suspendModal.setError(err);
    } finally {
      suspendModal.setLoading(false);
      setSuspensionReason(null);
    }
  };

  const tearDownTenant = async () => {
    tearDownTenantModal.setLoading(true);
    tearDownTenantModal.setError(null);

    await fetchData();

    try {
      // Revoke access for all members
      for (const user of users) {
        await NamespaceActions.removeTenantMember({
          UserId: user.UserId,
          Email: user.Email,
          NamespaceId: internalTenant.id,
          Roles: [],
        });
      }

      // Revoke pending invites
      for (const pendingInvite of pendingInvites) {
        await inviteActions.revokeInvite(pendingInvite.InviteId);
      }

      // Suspend tenant
      await NamespacesResource.setSuspension(tenantId, true, suspensionReason);

      await fetchData();
      tearDownTenantModal.close();
      setSuspensionReason(null);
    } catch (err) {
      tearDownTenantModal.setError(err);
    } finally {
      tearDownTenantModal.setLoading(false);
    }
  };

  const closeTearDownTenantModal = () => {
    tearDownTenantModal.close();
    setSuspensionReason(null);
    tearDownTenantModal.setError(null);
  };

  const reinstateTenant = async () => {
    reinstateModal.setLoading(true);
    reinstateModal.setError(null);
    try {
      const updatedTenant = await NamespacesResource.setSuspension(tenantId, false);
      setInternalTenant({ ...internalTenant, ...updatedTenant });
      reinstateModal.close();
    } catch (err) {
      reinstateModal.setError(err);
    } finally {
      reinstateModal.setLoading(false);
    }
  };

  const createSalesforceCpp = async () => {
    setLoading(true);
    try {
      const updatedTenant = await InternalTenantsResource.createSalesforceCpp(tenantId);
      setInternalTenant({ ...internalTenant, ...updatedTenant });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const [selectedTabId, setSelectedTabId] = useState(TabId.Users);

  const handleTabChange = (tabId: TabId) => {
    setSelectedTabId(tabId);
  };

  if (!canReadTenants) {
    return (
      <Page>
        <h5 data-testid="lookup-users-access-denied">Access Denied</h5>
      </Page>
    );
  }

  if (error) {
    return (
      <Page>
        <Alert type="danger" data-testid="user-tenant-error">
          {error}
        </Alert>
      </Page>
    );
  }

  if (loading) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }

  return (
    <Page fullWidth>
      <div className="tw-flex tw-mb-4 tw-space-x-4">
        <PermissionTip hasPermission={canInviteToTenant}>
          <Button
            fill="outlined"
            href={`#admin/projects/${internalTenant.id}/invite`}
            rel="noreferrer"
            data-testid="invite-users-to-tenant-button"
            disabled={!canInviteToTenant}
          >
            Invite Users to Tenant
          </Button>
        </PermissionTip>
        <PermissionTip hasPermission={canEditTenant}>
          <Button
            fill="outlined"
            href={`#admin/projects/${internalTenant.id}/settings`}
            rel="noreferrer"
            data-testid="edit-tenant-button"
            disabled={!canEditTenant}
          >
            Edit Tenant
          </Button>
        </PermissionTip>
        {internalTenant.planType === PlanType.SELF_SERVE && (
          <Button
            fill="outlined"
            onClick={createSalesforceCpp}
            data-testid="tenant-cpp-creation-button"
            disabled={!!internalTenant.salesforceCppId}
          >
            Create Salesforce CPP
          </Button>
        )}
        {!internalTenant.suspended && (
          <PermissionTip hasPermission={canSuspendTenant}>
            <Button
              color="danger"
              fill="outlined"
              onClick={suspendModal.show}
              data-testid="suspend-tenant-button"
              disabled={!canSuspendTenant}
            >
              Suspend Tenant
            </Button>
          </PermissionTip>
        )}
        {!internalTenant.suspended && (
          <PermissionTip hasPermission={canTearDownTenant}>
            <Button
              color="danger"
              fill="outlined"
              onClick={tearDownTenantModal.show}
              data-testid="tear-down-tenant-button"
              disabled={!canTearDownTenant}
            >
              Tear Down Tenant
            </Button>
          </PermissionTip>
        )}
      </div>
      <h5 className="tw-mb-4" data-testid="tenant-details-header">
        Tenant details for {internalTenant.friendlyName}
      </h5>

      {internalTenant.suspended && (
        <Alert
          type="danger"
          className="tw-mb-4"
          data-testid="suspended-banner"
          title="Tenant Suspended"
          {...(canEditTenant && {
            actions: [
              {
                label: 'Reinstate Tenant',
                onClick: reinstateModal.show,
                // Hacky spread - BannerActionProps doesn't recognise this as a prop
                ...{ 'data-testid': 'reinstate-tenant-button' },
              },
            ],
          })}
        >
          {internalTenant.suspensionReason && `Reason: '${internalTenant.suspensionReason}'.`}
        </Alert>
      )}

      <ul className="tw-list-disc tw-ml-8 tw-mb-4" data-testid="tenant-details">
        <li>
          ID: <code>{internalTenant.id}</code>
        </li>
        <li>
          Internal name: <code>{internalTenant.internalName}</code>
        </li>
        <li>Tenant type: {tenantTypeDisplayName(internalTenant.tenantType)}</li>
        <li>
          Salesforce Customer Project Profile:{' '}
          <code>{internalTenant.salesforceCppId ?? 'Not set'}</code>
        </li>
        <li>
          Isolation IDs: {!internalTenant?.isolationIds?.length && <code>Not set</code>}{' '}
          <PermissionTip hasPermission={canEditTenant}>
            <IconButton
              onClick={() => editIsolationIdsModal.setVisible(true)}
              iconName="PencilIconOutline"
              title="Edit isolation ids"
              aria-label="Edit isolation ids"
              size="small"
              clean
              disabled={!canEditTenant}
            />
          </PermissionTip>
          {internalTenant?.isolationIds?.length > 0 && (
            <ul className="tw-list-disc tw-ml-8">
              {internalTenant?.isolationIds?.map((id, index) => (
                <li key={index}>
                  <div className="tw-flex tw-items-center tw-gap-2">
                    {formatIsolationUnitId(id, internalTenant)}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </li>
        {internalTenant.organizationId && (
          <li>
            Organization ID:{' '}
            <Link href={`#admin/organizations/${internalTenant.organizationId}`}>
              {internalTenant.organizationId}{' '}
            </Link>
          </li>
        )}
        <li>
          Billing Method: <code>{internalTenant.billingMethod}</code>
        </li>
        {internalTenant.stripeUrl && (
          <>
            <li>
              <TextLink externalLink href={internalTenant.stripeUrl}>
                Stripe customer url
              </TextLink>
            </li>
            <li>
              Invoices status:{' '}
              {!internalTenant.hasUnpaidInvoices ? (
                <code>Up to date ✅</code>
              ) : (
                <code>Unpaid invoices ❌</code>
              )}
            </li>
          </>
        )}
        {internalTenant.planType === PlanType.ENTERPRISE && (
          <li>
            <div className="tw-flex tw-items-center tw-gap-2">
              Pricing plan:{' '}
              <code>
                {internalTenant.pricingPlanName
                  ? `${internalTenant.pricingPlanName} (updated ${format(
                      new Date(internalTenant.pricingPlanStartDate),
                      'yyyy-MM-dd HH:mm:ss X'
                    )})`
                  : 'Not set'}
              </code>{' '}
              <PermissionTip hasPermission={canEditTenant}>
                <IconButton
                  onClick={() => editPricingPlanModal.setVisible(true)}
                  iconName="PencilIconOutline"
                  title="Edit pricing plan"
                  aria-label="Edit pricing plan"
                  size="small"
                  clean
                  disabled={!canEditTenant}
                  data-testid="edit-pricing-plan"
                />
              </PermissionTip>
            </div>
          </li>
        )}
      </ul>

      <Tabs className="tw-mb-8" value={selectedTabId} onChange={handleTabChange}>
        <Tabs.Tab tabId={TabId.Users}>Users</Tabs.Tab>
        <Tabs.Tab data-testid="invites-tab" tabId={TabId.Invites}>
          Invites
        </Tabs.Tab>
        <Tabs.Tab tabId={TabId.Databases}>Databases</Tabs.Tab>
        <Tabs.Tab tabId={TabId.SsoLogins}>SSO Logins</Tabs.Tab>
        <Tabs.Tab tabId={TabId.FeatureToggles}>Feature Toggles</Tabs.Tab>
      </Tabs>

      <Tabs.TabPanel tabId={TabId.Users} value={selectedTabId}>
        <UserTab
          users={users}
          tenant={internalTenant}
          usersRole={usersRole}
          refetchUsers={refetchUsers}
        />
      </Tabs.TabPanel>

      <Tabs.TabPanel tabId={TabId.Invites} value={selectedTabId}>
        <InviteTab
          pendingInvites={pendingInvites}
          handleInviteUpdate={handleInviteUpdate}
          tenant={internalTenant}
          expiredInvites={expiredInvites}
        />
      </Tabs.TabPanel>

      <Tabs.TabPanel tabId={TabId.Databases} value={selectedTabId}>
        <ViewTenantDatabases tenantId={internalTenant.id} />
      </Tabs.TabPanel>

      <Tabs.TabPanel tabId={TabId.SsoLogins} value={selectedTabId}>
        <TenantSsoConfigs tenant={internalTenant} />
      </Tabs.TabPanel>

      <Tabs.TabPanel tabId={TabId.FeatureToggles} value={selectedTabId}>
        <FeatureToggleOverridesPane tenantId={tenantId} className="tw-mt-8 tw-mb-4" />
      </Tabs.TabPanel>

      <SuspendTenantModal
        open={suspendModal.visible}
        onClose={suspendModal.close}
        loading={suspendModal.loading}
        error={suspendModal.error}
        onConfirm={suspendTenant}
        suspensionReason={suspensionReason}
        setSuspensionReason={setSuspensionReason}
      />
      <ReinstateTenantModal
        open={reinstateModal.visible}
        onClose={reinstateModal.close}
        loading={reinstateModal.loading}
        error={reinstateModal.error}
        onConfirm={reinstateTenant}
      />
      <EditIsolationIdsModal
        tenant={internalTenant}
        open={editIsolationIdsModal.visible}
        onSuccess={handleEditIsolationIDsSuccess}
        onClose={editIsolationIdsModal.close}
      />
      <EditPricingPlanModal
        tenant={internalTenant}
        open={editPricingPlanModal.visible}
        onSuccess={handleEditPricingPlanSuccess}
        onClose={editPricingPlanModal.close}
      />
      <TearDownTenantModal
        open={tearDownTenantModal.visible}
        onClose={closeTearDownTenantModal}
        loading={tearDownTenantModal.loading}
        error={tearDownTenantModal.error}
        onConfirm={tearDownTenant}
        suspensionReason={suspensionReason}
        setSuspensionReason={setSuspensionReason}
        tenant={internalTenant}
      />
    </Page>
  );
};

export default UserTenantsPage;
