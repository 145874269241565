import React, { useState } from 'react';
import LoadError from 'components/application/load-error';
import { LoadingSpinner, Page, Tabs } from 'components/foundation';
import { useOrganizationQuery } from 'remote/resources/organizations';
import { Organization } from 'types/organization';
import { KeyPairItem, KeyPairSection } from 'components/application/key-pair';
import { OrganizationSsoConfigs } from './organization-sso';
import { OrganizationTenants } from './organization-tenants';
import { OrganizationMembers } from './organization-members';
import { auth0Domain } from 'utils/auth0';

const OrganizationDetailsGuard = (
  component: React.ComponentType<{ organization: Organization; onRefreshOrganization: () => void }>
) => {
  return ({ organizationId }: { organizationId: string }) => {
    const organizationQuery = useOrganizationQuery(organizationId); // eslint-disable-line

    const handleRefreshOrganization = () => organizationQuery.refetch();

    if (organizationQuery.isLoading) {
      return <LoadingSpinner size="large" expand minHeight={300} />;
    }

    if (organizationQuery.isError) {
      return <LoadError />;
    }

    const organization = organizationQuery.data;

    const Component = component;
    return (
      <Component organization={organization} onRefreshOrganization={handleRefreshOrganization} />
    );
  };
};

enum TabId {
  Tenants = 'tenants',
  Members = 'members',
  SsoLogins = 'ssologins',
}

export const OrganizationDetailsBase = ({
  organization,
  onRefreshOrganization,
}: {
  organization: Organization;
  onRefreshOrganization: () => void;
}) => {
  const loginWithOrgLink = `${location.origin}?org_id=${organization.ssoOrgId}&autosubmit=true#organization-lookup`;

  const [selectedTabId, setSelectedTabId] = useState(TabId.Tenants);
  return (
    <Page fullWidth>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <div>
          <h3>{organization.displayName}</h3>
        </div>
        <div data-testid="org-info">
          <KeyPairSection>
            <KeyPairItem label="ID">{organization.id}</KeyPairItem>
            <KeyPairItem
              label="Organization SSO ID (Auth0 Organization ID)"
              href={`${auth0Domain}/organizations/${organization.ssoOrgId}/overview`}
            >
              {organization.ssoOrgId}
            </KeyPairItem>
            <KeyPairItem label="Name">{organization.name}</KeyPairItem>
            <KeyPairItem label="Display Name">{organization.displayName}</KeyPairItem>
            <KeyPairItem label="Plan type">{organization.planType}</KeyPairItem>
            <KeyPairItem label="Profile">{organization.profile}</KeyPairItem>
            {organization.ssoOrgId && (
              <KeyPairItem label="Login with org SSO Link" href={loginWithOrgLink}>
                {loginWithOrgLink}
              </KeyPairItem>
            )}
          </KeyPairSection>
        </div>

        <Tabs<TabId> className="tw-mb-8" value={selectedTabId} onChange={setSelectedTabId}>
          <Tabs.Tab tabId={TabId.Tenants}>Tenants</Tabs.Tab>
          <Tabs.Tab tabId={TabId.Members}>Members</Tabs.Tab>
          <Tabs.Tab tabId={TabId.SsoLogins}>SSO Logins</Tabs.Tab>
        </Tabs>
        <Tabs.TabPanel tabId={TabId.Tenants} value={selectedTabId}>
          <OrganizationTenants organization={organization} />
        </Tabs.TabPanel>
        <Tabs.TabPanel tabId={TabId.Members} value={selectedTabId}>
          <OrganizationMembers organization={organization} />
        </Tabs.TabPanel>
        <Tabs.TabPanel tabId={TabId.SsoLogins} value={selectedTabId}>
          <OrganizationSsoConfigs
            organization={organization}
            onRefreshOrganization={onRefreshOrganization}
          />
        </Tabs.TabPanel>
      </div>
    </Page>
  );
};

export const OrganizationDetails = OrganizationDetailsGuard(OrganizationDetailsBase);
