import React from 'react';

interface TenantNameProps {
  tenantId: string;
  href?: string;
  name: string;
}
const TenantName = ({ tenantId, name, href = null }: TenantNameProps) => {
  const Element = href ? 'a' : 'span';
  if (name && name.length > 0) {
    return (
      <Element href={href} className="db-name" data-testid="tenant-name" title={name}>
        {name}
      </Element>
    );
  }
  return (
    <Element href={href} className="db-name" data-testid="tenant-name">
      Unnamed Tenant: {tenantId}
    </Element>
  );
};

export default TenantName;
