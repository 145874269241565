import UsageResource from 'remote/resources/usage';
import {
  TransformedBreakdown,
  TransformedDiscount,
  TransformedUsage,
  Filter,
  DateFilter,
  Pagination,
  Sorting,
} from 'types/usage';

const fetch = (
  tenantId: string,
  filters?: Filter[] | DateFilter[],
  pagination?: Pagination,
  sorting?: Sorting,
  searchFilter?: string
): Promise<TransformedUsage> =>
  UsageResource.get(tenantId, filters, pagination, sorting, searchFilter).then(
    ({
      total_cost,
      total_cost_type,
      total_breakdown_count,
      discount,
      breakdown,
      has_previous_pricing_plan,
      usage_period_end,
      usage_period_start,
    }) => {
      let transformedDiscount: TransformedDiscount;

      if (discount) {
        transformedDiscount = {
          promotionName: discount.promotion_name,
          voucherCode: discount.voucher_code,
          dollarAmountOff: discount.dollar_amount_off,
        };
      }

      let transformedBreakdown: TransformedBreakdown;

      if (breakdown) {
        transformedBreakdown = breakdown.map(dbUsage => ({
          dbid: dbUsage.dbid,
          name: dbUsage.name,
          consumptionUnits: dbUsage.consumption_units,
          unitType: dbUsage.unit_type,
          unitCostInDollars: dbUsage.unit_cost_in_dollars,
          cost: dbUsage.cost,
          costType: dbUsage.cost_type,
          billingCategory: dbUsage.billing_category,
          endTime: dbUsage.end_time,
          tier: dbUsage.tier,
        }));
      }

      return {
        totalCost: total_cost,
        totalCostType: total_cost_type,
        totalBreakdownCount: total_breakdown_count,
        discount: transformedDiscount,
        breakdown: transformedBreakdown,
        hasPreviousPricingPlan: has_previous_pricing_plan,
        usagePeriodEnd: usage_period_end,
        usagePeriodStart: usage_period_start,
      };
    }
  );

export default { fetch };
