import React, { useState, useEffect } from 'react';
import { Button, Radio, Menu, IconButton } from 'components/foundation';
import { DatePicker, DatePickerProps } from '@neo4j-ndl/react';
import { useTracking } from 'react-tracking';
import { MAXIMUM_DAYS, FilterReportsSelection, DATE_SELECTION_OPTIONS } from './const';
import { Filter, DateFilter, DateRange, FilterType } from 'types/usage';
import { generateDateFilter, getMaximumDateRange } from './utils';

type Props = {
  open: boolean;
  anchorEl: any;
  filters: Filter[] | DateFilter[];
  requestPending: boolean;
  onClose: () => void;
  onUpdate: (filters: Filter[] | DateFilter[]) => void;
};

const FilterReportsMenu = ({
  open,
  anchorEl,
  requestPending,
  filters,
  onUpdate,
  onClose,
}: Props) => {
  const tracking = useTracking();

  // The maximum start date for the custom range is one year ago
  const [minStart, maxEnd] = getMaximumDateRange(MAXIMUM_DAYS);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([minStart, maxEnd]);
  const [filterDateSelection, setFilterDateSelection] = useState<FilterReportsSelection>(
    FilterReportsSelection.START_OF_MONTH
  );
  const [errorMessage, setErrorMessage] = useState<string>(null);

  // In case the user deletes the filter after submission we should reset the date range
  useEffect(() => {
    const hasDateFilter = filters.some(filter => filter.type === FilterType.DATE);
    if (!hasDateFilter) {
      setFilterDateSelection(null);
      setSelectedDateRange([minStart, maxEnd]);
    }
  }, [filters]);

  const trackTriggerFiltering = (filter: { type: FilterType.DATE; start: Date; end: Date }) => {
    tracking.trackEvent({
      action: 'apply_filter_billing_reports',
      filter: filter,
    });
  };

  const handleSubmit = () => {
    setErrorMessage(null);

    if (filterDateSelection) {
      const { label, value, start, end } = generateDateFilter(
        filterDateSelection,
        selectedDateRange
      );
      trackTriggerFiltering({
        type: FilterType.DATE,
        start,
        end,
      });
      onUpdate([{ label, type: FilterType.DATE, value, start, end }]);
    }
  };

  const handleCustomRangeChange: DatePickerProps<true>['reactDatePickerProps']['onChange'] = (
    selectedDateRange: DateRange
  ) => {
    setSelectedDateRange(selectedDateRange);
  };

  return (
    <Menu open={open} anchorEl={anchorEl} onClose={onClose} captureFocus={true}>
      <Menu.Items>
        <div className="tw-flex tw-justify-between">
          <div className="tw-p-2">
            <h6>Filter</h6>
          </div>
          <div>
            <IconButton
              iconName="XMarkIconOutline"
              onClick={onClose}
              title="Close"
              aria-label="Close"
              clean
            />
          </div>
        </div>
        <div className="tw-py-3 tw-px-2">
          <span className="subheading-medium">Date Range</span>
        </div>
        <div className="tw-mx-2">
          {DATE_SELECTION_OPTIONS.map(({ label, value }) => (
            <Radio
              role="menuitem"
              className="tw-my-3"
              checked={
                filterDateSelection === value ||
                (value === FilterReportsSelection.START_OF_MONTH && !filterDateSelection)
              } // in case the user has not selected any date the default is start of month
              disabled={requestPending}
              onChange={() => {
                setFilterDateSelection(value);
              }}
              label={label}
              value={value}
              key={value}
            />
          ))}
        </div>
        <div>
          <p className="n-body-medium tw-mx-2 n-text-palette-neutral-text-weaker">Date</p>
          <div className="tw-ml-1">
            <DatePicker
              disabled={filterDateSelection !== FilterReportsSelection.CUSTOM_RANGE}
              reactDatePickerProps={{
                startDate: selectedDateRange[0],
                endDate: selectedDateRange[1],
                minDate: minStart,
                maxDate: maxEnd,
                dateFormat: 'dd/MM/yyyy',
                selectsRange: true,
                onChange: handleCustomRangeChange,
                popperProps: {
                  strategy: 'fixed',
                },
              }}
            />
          </div>
        </div>
        <div className="tw-flex tw-justify-end tw-mx-2 tw-mb-4 tw-mt-6">
          <Button
            onClick={onClose}
            data-testid="filter-reports-cancel"
            aria-label="Cancel"
            size="small"
            className="tw-mr-2"
            fill="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={requestPending || !!errorMessage || !filterDateSelection}
            data-testid="filter-reports-submit"
            aria-label="Submit"
            size="small"
          >
            Apply
          </Button>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default FilterReportsMenu;
