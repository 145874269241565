import React, { useEffect, useState } from 'react';
import { Button, Dialog, Divider, LoadingSpinner, Page, TextLink, Alert } from 'foundation';
import track, { useTracking } from 'react-tracking';

import Actions from 'actions';

import formatDollars from 'utils/format-dollars';

import { PlanType } from 'entities/tenant';
import { useDatabaseState, useSession } from 'store';
import { useDatabasesRequest } from 'helpers/databases';
import LoadError from 'application/load-error';
import { OauthClientSection } from './oauth';
import classNames from 'classnames';
import { Database } from 'entities/database';
import { SessionState } from 'state/session-store';

interface AccountDetailsSectionProps {
  session: SessionState;
  buttonSuspend: boolean;
  supportSuspend: boolean;
  numberOfDatabases: number;
}

const AccountDetailsSection = ({
  session,
  buttonSuspend,
  supportSuspend,
  numberOfDatabases,
}: AccountDetailsSectionProps) => {
  const [suspendAccountModalVisible, setSuspendAccountModalVisible] = useState(false);
  const tracking = useTracking();

  return (
    <div className="tw-my-4" data-testid="account-details">
      <h4 className="tw-my-8">Account Details</h4>
      <div className="tw-grid tw-grid-cols-2 tw-gap-x-1 tw-mb-4 tw-w-full">
        <div className="tw-self-center">
          {session.name}
          <div className="tw-font-bold" title={session.email}>
            {session.email}
          </div>
        </div>
        <div className="tw-self-center tw-ml-auto">
          {buttonSuspend && (
            <Button
              color="neutral"
              fill="text"
              data-testid="suspend-account"
              iconName="StopCircleIconOutline"
              title="Suspend account"
              onClick={() => {
                tracking.trackEvent({
                  action: 'suspend_account_open',
                  properties: { event_label: 'clicked' },
                });
                setSuspendAccountModalVisible(true);
              }}
            >
              Suspend account
            </Button>
          )}
        </div>
      </div>
      {supportSuspend && (
        <Alert icon type="info">
          <div className="tw-self-end" data-testid="request-account-cancellation">
            To request a change to user access or permissions, please visit the{' '}
            <TextLink
              href="https://support.neo4j.com/s/"
              rel="noopener noreferrer"
              data-testid="request-account-cancellation-link"
              externalLink
            >
              Aura Support Portal
            </TextLink>
            .
          </div>
        </Alert>
      )}

      {suspendAccountModalVisible && (
        <SuspendAccountModal
          open={suspendAccountModalVisible}
          numberOfDatabases={numberOfDatabases}
          close={() => {
            setSuspendAccountModalVisible(false);
          }}
          onConfirm={() => {
            tracking.trackEvent({
              action: 'suspend_account_confirm',
              properties: { event_label: 'clicked' },
            });
            Actions.auth.logout();
          }}
          session={session}
        />
      )}
    </div>
  );
};

const SuspendDenyModalContent = ({ onConfirm }) => {
  const tracking = useTracking();

  tracking.trackEvent({
    action: 'suspend_account_deny',
  });
  return (
    <>
      <Dialog.Description>
        You need to delete all running databases before we can suspend your account.
      </Dialog.Description>
      <Dialog.Actions>
        <Button onClick={onConfirm} title="Close dialog" data-testid="suspend-close">
          Close
        </Button>
      </Dialog.Actions>
    </>
  );
};

interface UsageInformation {
  loading: boolean;
  discount?: { dollarAmountOff: string };
  totalCost?: string;
  totalCostType?: string;
}

interface UsageInformationProps {
  onConfirm: () => void;
  onCancel: () => void;
  tenantId: string;
}

const SuspendApprovedModalContent = ({ onConfirm, onCancel, tenantId }: UsageInformationProps) => {
  const [usage, setUsage] = useState<UsageInformation>({ loading: true });

  useEffect(() => {
    Actions.billingUsage.fetch(tenantId).then(currentUsage => {
      setUsage({
        loading: false,
        ...currentUsage,
      });
    });
  }, []);

  const { discount, totalCost } = usage;
  const reduction = discount ? parseFloat(discount.dollarAmountOff) : 0;

  return (
    <>
      {usage.loading && <LoadingSpinner />}
      {!usage.loading && (
        <>
          <Dialog.Description>
            <p>
              You will be billed{' '}
              <strong key="amount">
                {formatDollars(Math.abs(parseFloat(totalCost) - reduction))} at the end of the
                month.
              </strong>
            </p>
            <p>
              {
                "Thank you for using Neo4j Aura, we're sad to see you go. Just log back in anytime to reactivate your account."
              }
            </p>
            <p>
              <em>{"Click 'Suspend account' to confirm"}</em>
            </p>
          </Dialog.Description>
          <Dialog.Actions>
            <Button
              color="neutral"
              fill="outlined"
              onClick={onCancel}
              title="Close dialog"
              data-testid="suspend-cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              title="Confirm suspend account"
              data-testid="suspend-confirm"
            >
              Suspend account
            </Button>
          </Dialog.Actions>
        </>
      )}
    </>
  );
};

const SuspendAccountModal = ({ open, numberOfDatabases, close, onConfirm, session }) => {
  const tracking = useTracking();

  return (
    <Dialog
      size="small"
      modalProps={{ 'data-testid': 'suspend-account-modal' }}
      open={open}
      onClose={close}
    >
      <Dialog.Header>Suspend Account</Dialog.Header>
      {numberOfDatabases > 0 && <SuspendDenyModalContent onConfirm={close} />}
      {numberOfDatabases <= 0 && (
        <SuspendApprovedModalContent
          onConfirm={onConfirm}
          onCancel={() => {
            tracking.trackEvent({
              action: 'suspend_account_cancel',
              properties: { event_label: 'clicked' },
            });
            close();
          }}
          tenantId={session.tenant.id}
        />
      )}
    </Dialog>
  );
};

const AccountGuarded = props => {
  const dbsRequest = useDatabasesRequest();
  const session = useSession();
  const { databases } = useDatabaseState();

  if (dbsRequest.loading) return <LoadingSpinner size="large" expand />;
  if (dbsRequest.error) return <LoadError />;

  return <Account {...props} databases={databases} session={session} />;
};

interface AccountProps {
  databases?: Database[];
  session: SessionState;
}

export const Account = ({ databases = [], session }: AccountProps) => {
  const { planType } = session.tenant;

  const showSupportSuspend = planType === PlanType.ENTERPRISE;

  const showOauthClientSection = !!session.featureToggles.enable_aura_api_ga;

  const panelClasses = classNames(
    'tw-p-8',
    'tw-bg-palette-neutral-bg-weak',
    'tw-border-none',
    'tw-shadow-raised',
    'tw-rounded-lg'
  );

  return (
    <Page plain={true} data-testid="my-account-page" fullWidth>
      <div
        className={panelClasses}
        style={{
          minWidth: 'var(--breakpoints-xs)',
        }}
      >
        <div data-testid="account-details-segment">
          <AccountDetailsSection
            session={session}
            numberOfDatabases={databases.length}
            buttonSuspend={false}
            supportSuspend={showSupportSuspend}
          />
          {showOauthClientSection && (
            <>
              <Divider />
              <OauthClientSection />
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default track()(AccountGuarded);
