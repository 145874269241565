import React from 'react';

import LoginPage from 'pages/login';
import EmailVerificationRequiredPage from 'pages/login/unverified';
import DatabasesPage from 'pages/databases';
import DatabaseDetailPage from 'pages/databases/detail'; // eslint-disable-line import/no-named-as-default
import CreateDatabasePage from 'pages/databases/create';
import NewDatabasePage from 'pages/databases/new-db';
import UserManagementPage from 'components/pages/user-management';
import LogForwardingPage from 'pages/log-forwarding';
import InvitePage from 'pages/admin/invite';
import { LookupDatabasePage, ViewDatabaseDetailsPage } from 'pages/admin/databases';
import TenantPage from 'pages/admin/user-tenants/edit-tenant/index';
import CreateTenantPage from 'pages/admin/create-tenant';
import Account from 'pages/account';
import LookupUsersPage from 'pages/admin/lookup-users';
import AuthorizeUsersPage from 'pages/admin/authorize-users';
import LookupTenantPage from 'pages/admin/lookup-tenant';
import LookupOAuthClientPage from 'pages/admin/lookup-oauth-client';
import UserTenantsPage from 'pages/admin/user-tenants';
import FeatureTogglesOverviewPage from 'pages/admin/feature-toggles-overview';
import { TenantNotFoundPage } from 'pages/tenant-not-found';
import { SessionStore } from 'state';
import { SessionState } from 'state/session-store';

import { TenantType, PlanType } from 'entities/tenant';
import { currentTsAndCsVersion } from '../tsandcs/content';
import TermsAndConditionsPage, { TermsAndConditionsMinimalViewRoute } from '../tsandcs';
import { match } from './helpers';
import { productGuard } from './guards';
import { Redirect } from './redirect';
import TransferInstancePage, { TransferType } from 'pages/admin/transfer-instance';
import { InviteConsentPage } from 'pages/invite-consent';
import SsoConfigPage, { SsoConfigDetailsPage } from '../admin/sso-config';
import GlobalSettingsPage from '../settings';
import { isLogForwardingEnabled } from 'entities/log-forwarding';
import MarketplaceRegistrationPage from 'pages/marketplace-registration';
import CmekPage from 'pages/cmek';
import NetworkPage from 'pages/network';
import { isCmekEnabled } from 'entities/encryption-keys';
import { isTrafficConfigEnabled } from 'entities/traffic-config';
import LinkAccountsPage from 'pages/link-accounts';
import { OrganizationDetails } from '../admin/organization';
import CreateOrganizationPage from '../admin/create-organization';
import { LookupOrganizationPage } from '../admin/lookup-organization';
import { shouldShowInvitesConsentForm } from 'application/invite-consent';
import { isMetricsIntegrationEnabled } from 'entities/metrics-integration';
import MetricIntegrationPage from '../metrics-integration';
import GdsSessionsPage from '../gds-sessions';
import { isGdsSessionsEnabled } from 'entities/gds-sessions';
import OrganizationSettingsPage from '../organization-settings';
import { BUSINESS_CRITICAL_TIER, Tier } from 'entities/database';
import { OrgPageSuffix } from '../organization-settings/types';

const loadingRoutes = [
  {
    description: 'Login-in-progress Page',
    routeMatcher: match(/access_token=.*/),
    render: () => <LoginPage loading={true} />,
  },
];

const infoRoutes = [
  {
    description: 'Review Terms and Conditions Page',
    routeMatcher: match(/terms/),
    render: () => {
      const acceptedVersion = SessionStore.state.acceptedTsAndCs;
      return (
        <TermsAndConditionsPage
          updatedVersion={acceptedVersion < currentTsAndCsVersion()}
          accepted={acceptedVersion === currentTsAndCsVersion()}
          loggedIn={true}
        />
      );
    },
  },
];

const prerequisites = [
  {
    requiresTsAndCs: false,
    description: 'Email Verification Required Page By Session State',
    stateMatcher: () => SessionStore.state.emailVerificationRequired,
    render: () => <EmailVerificationRequiredPage />,
  },
  {
    // Used by Cypress tests
    requiresTsAndCs: false,
    description: 'Email Verification Required Page By URL',
    routeMatcher: match(/email-verification-required/),
    render: () => <EmailVerificationRequiredPage />,
  },
  {
    requiresTsAndCs: false,
    description: 'Link Accounts Required by Session State',
    stateMatcher: () => !!SessionStore.state.accountToLink,
    minimalView: true,
    render: () => <LinkAccountsPage />,
  },
  {
    requiresTsAndCs: false,
    description: 'Link Accounts Required by URL',
    routeMatcher: match(/link-accounts/),
    minimalView: true,
    render: () => <LinkAccountsPage />,
  },
  {
    description: 'Invites',
    requiresTsAndCs: false,
    minimalView: true,
    stateMatcher: () => {
      const { acceptedTsAndCs, tenant } = SessionStore.state;
      const showInvites = shouldShowInvitesConsentForm(SessionStore.state);
      return !acceptedTsAndCs && showInvites && tenant.tenantType === TenantType.PERSONAL;
    },
    render: () => <InviteConsentPage />,
  },
  {
    description: 'Accept Terms & Conditions Page',
    requiresTsAndCs: false,
    minimalView: true,
    stateMatcher: () => {
      const selfServeUser = SessionStore.state.planType === PlanType.SELF_SERVE;
      const hasAcceptedLatestTerms = SessionStore.state.acceptedTsAndCs === currentTsAndCsVersion();
      return selfServeUser && !hasAcceptedLatestTerms;
    },
    render: () => <TermsAndConditionsMinimalViewRoute />,
  },
  {
    description: 'Tenant 403 or 404 page',
    requiresTsAndCs: true,
    stateMatcher: () => SessionStore.state.tenantNotFound,
    render: () => <TenantNotFoundPage />,
  },
];

const billingFlows = [
  {
    description: 'Account',
    requiresTsAndCs: true,
    routeMatcher: match(/account/, /^$|billing|usage/),
    loader: ({ hierarchy }) => ({
      tab: hierarchy.length > 1 ? hierarchy[1] : null,
    }),
    render: ({ tab }) => <Account tab={tab} />,
  },
  {
    description: 'New User Wizard',
    routeMatcher: match(/create-database-wizard/),
    render: () => <Redirect hash="#create-database" />,
  },
];

const DatabasesPageGuarded = productGuard(DatabasesPage);
const CreateDatabasePageGuarded = productGuard(CreateDatabasePage);

const generalRoutes = [
  {
    description: 'Databases List Page',
    routeMatcher: match(/databases/, /^$|create/),
    render: () => <DatabasesPageGuarded />,
  },
  {
    description: 'Database Detail Page',
    routeMatcher: match(/databases/, /.*/, /detail/),
    loader: ({ instanceId }) => ({ instanceId }),
    render: ({ instanceId }) => <DatabaseDetailPage dbId={instanceId} />,
  },
  {
    description: 'Create Database Page',
    routeMatcher: match(/create-database/, /.*/),
    loader: ({ instanceId }) => ({
      tier: instanceId === BUSINESS_CRITICAL_TIER ? Tier.MTE : instanceId,
    }),
    render: ({ tier }) => <CreateDatabasePageGuarded tier={tier} />,
  },
  {
    description: 'Database Created Page',
    routeMatcher: match(/databases/, /.*/, /created/),
    loader: ({ instanceId }) => ({ instanceId }),
    render: ({ instanceId }) => <NewDatabasePage dbId={instanceId} />,
  },
  {
    description: 'Error Test Page',
    routeMatcher: match(/error-test/),
    render: () => {
      const ErrorTrigger = () => {
        throw new Error('Error test.');
      };
      return <ErrorTrigger />;
    },
  },
  {
    description: 'User Management Page',
    routeMatcher: match(/user-management/),
    render: () => <UserManagementPage />,
  },
  {
    description: 'Log Forwarding Page',
    routeMatcher: match(/log-forwarding/),
    stateMatcher: (session: SessionState) => isLogForwardingEnabled(session),
    render: () => <LogForwardingPage />,
  },
  {
    description: 'Metrics Integration Page',
    routeMatcher: match(/metrics-integration/),
    stateMatcher: (session: SessionState) => isMetricsIntegrationEnabled(session),
    render: () => <MetricIntegrationPage />,
  },
  {
    description: 'Customer Managed Keys Page',
    routeMatcher: match(/customer-managed-keys/),
    stateMatcher: (session: SessionState) => isCmekEnabled(session),
    render: () => <CmekPage />,
  },
  {
    description: 'Network Access Page',
    routeMatcher: match(/network/),
    stateMatcher: (session: SessionState) => isTrafficConfigEnabled(session),
    render: () => <NetworkPage />,
  },
  {
    desciption: 'Global Settings',
    routeMatcher: match(/settings/, /^$|preferences|billing/),
    render: () => <GlobalSettingsPage />,
  },
  {
    description: 'Sessions Page',
    routeMatcher: match(/sessions/),
    stateMatcher: (session: SessionState) => isGdsSessionsEnabled(session),
    render: () => <GdsSessionsPage />,
  },
  {
    desciption: 'Organization settings',
    routeMatcher: match(
      /organizations/,
      /.*/,
      new RegExp(`${Object.values(OrgPageSuffix).join('|')}`)
    ),
    loader: ({ instanceId }) => ({ organizationId: instanceId }),
    render: ({ organizationId }) => <OrganizationSettingsPage organizationId={organizationId} />,
  },
];

const adminRoutes = [
  {
    description: 'Invite User Page',
    routeMatcher: match(/admin/, /(projects|tenants)/, /.*/, /invite/),
    loader: ({ instanceId }) => ({ tenantId: instanceId }),
    render: ({ tenantId }) => {
      return <InvitePage tenantId={tenantId} />;
    },
  },
  {
    description: 'Edit Tenant Page',
    routeMatcher: match(/admin/, /(projects|tenants)/, /.*/, /settings/),
    loader: ({ instanceId }) => ({ tenantId: instanceId }),
    render: ({ tenantId }) => {
      return <TenantPage tenantId={tenantId} />;
    },
  },
  {
    description: 'User Tenants Page',
    routeMatcher: match(/admin/, /(projects|tenants)/, /.*/, /users/),
    loader: ({ instanceId }) => ({ tenantId: instanceId }),
    render: ({ tenantId }) => {
      return <UserTenantsPage tenantId={tenantId} />;
    },
  },
  {
    description: 'Feature Toggles Overview',
    routeMatcher: match(/admin/, /feature-toggles/),
    render: () => {
      return <FeatureTogglesOverviewPage />;
    },
  },
  {
    description: 'Create Tenant Page',
    routeMatcher: match(/admin/, /(projects|tenants)/, /create/),
    render: () => {
      return <CreateTenantPage />;
    },
  },
  {
    description: 'Authorize Users Page',
    routeMatcher: match(/admin/, /authorize-users/),
    render: () => {
      return <AuthorizeUsersPage />;
    },
  },
  {
    description: 'Lookup Users Page',
    routeMatcher: match(/admin/, /users/),
    render: () => {
      return <LookupUsersPage />;
    },
  },
  {
    description: 'Lookup Tenant Page',
    routeMatcher: match(/admin/, /(projects|tenants)/),
    render: () => {
      return <LookupTenantPage />;
    },
  },
  {
    description: 'Lookup OAuth Clients Page',
    routeMatcher: match(/admin/, /oauth/),
    render: () => {
      return <LookupOAuthClientPage />;
    },
  },
  {
    description: 'Transfer Instance to User Page',
    routeMatcher: match(/admin/, /transfer-instance/),
    render: () => {
      return <TransferInstancePage transferType={TransferType.TRANSFER_TO_USER} />;
    },
  },
  {
    description: 'Transfer Instance to Tenant Page',
    routeMatcher: match(/admin/, /transfer-to-tenant/),
    render: () => {
      return <TransferInstancePage transferType={TransferType.TRANSFER_TO_TENANT} />;
    },
  },
  {
    description: 'Manage SSO Configs Page',
    routeMatcher: match(/admin/, /sso-configs/),
    render: () => {
      return <SsoConfigPage />;
    },
  },
  {
    description: 'SSO Config Page',
    routeMatcher: match(/admin/, /sso-configs/, /.*/),
    loader: ({ instanceId }) => ({ ssoConfigId: instanceId }),
    render: ({ ssoConfigId }) => {
      return <SsoConfigDetailsPage ssoConfigId={ssoConfigId} />;
    },
  },
  {
    description: 'Lookup Database Page',
    routeMatcher: match(/admin/, /databases/),
    render: () => {
      return <LookupDatabasePage />;
    },
  },
  {
    description: 'View Database Details Page',
    routeMatcher: match(/admin/, /databases/, /.*/),
    loader: ({ instanceId }) => ({ dbid: instanceId }),
    render: ({ dbid }) => {
      return <ViewDatabaseDetailsPage dbid={dbid} />;
    },
  },
  {
    description: 'Lookup Organizations',
    routeMatcher: match(/admin/, /organizations/),
    render: () => {
      return <LookupOrganizationPage />;
    },
  },
  {
    description: 'Organizations Create',
    routeMatcher: match(/admin/, /organizations/, /create/),
    render: () => {
      return <CreateOrganizationPage />;
    },
  },
  {
    description: 'Organization',
    routeMatcher: match(/admin/, /organizations/, /.*/),
    loader: ({ instanceId }) => ({ organizationId: instanceId }),
    render: ({ organizationId }) => {
      return <OrganizationDetails organizationId={organizationId} />;
    },
  },
  {
    description: 'Create Tenant Page',
    routeMatcher: match(/admin/, /organizations/, /.*/, /tenants/, /create/),
    loader: ({ instanceId }) => ({ organizationId: instanceId }),
    render: ({ organizationId }) => {
      return <CreateTenantPage organizationId={organizationId} />;
    },
  },
  {
    description: 'Marketplace Registration Page',
    routeMatcher: match(/marketplace-registration/),
    minimalView: true,
    render: () => <MarketplaceRegistrationPage />,
  },
];

export const privateRoutes = [
  ...loadingRoutes,
  ...infoRoutes,
  ...prerequisites,
  ...billingFlows,
  ...generalRoutes,
  ...adminRoutes,
];
