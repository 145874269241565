import LoadError from 'components/application/load-error';
import { Alert, Button, LoadingSpinner, Page, Tip } from 'components/foundation';
import { Tier } from 'entities/database';
import { Tenant } from 'entities/tenant';
import React, { useState } from 'react';
import { useTrafficConfigs } from 'remote/resources/traffic-configs';
import { usePermissions, useSession } from 'store';
import { TrafficConfig, TrafficStatus } from 'types/traffic-configs';
import { Action } from 'types/user';
import { TrafficConfigDialog } from './dialog';
import { TrafficConfigsTable } from './table';
import { isSameTrafficConfig } from './utils';
import { useDatabaseQuery } from 'remote/resources/databases';

/**
 * This component mostly exists to make sure we only trigger
 * the traffic configs query after we have loaded in our tenant
 */
const NetworkContent = ({
  tenant,
  createOpen,
  onCreateClose,
}: {
  tenant: Tenant;
  createOpen: boolean;
  onCreateClose: () => void;
}) => {
  const configsQuery = useTrafficConfigs(tenant.id);
  const dbsQuery = useDatabaseQuery(tenant.id);
  const [createdTrafficConfig, setCreatedTrafficConfig] = useState<TrafficConfig | null>(null);

  const handleClose = () => {
    setCreatedTrafficConfig(null);
    onCreateClose();
  };
  const handleCreateSuccess = config => {
    setCreatedTrafficConfig(config);
    configsQuery.refetch();
  };
  const handleUpdateSuccess = () => configsQuery.refetch();
  const handleDeleteSuccess = () => configsQuery.refetch();

  if (configsQuery.isLoading || dbsQuery.isLoading) {
    return <LoadingSpinner size="large" expand />;
  }

  if (configsQuery.isLoadingError || dbsQuery.isLoadingError) {
    return <LoadError />;
  }

  const databases = dbsQuery.data;
  const trafficConfigs = configsQuery.data;
  const liveCreatedTrafficConfig = createdTrafficConfig
    ? trafficConfigs.find(c => isSameTrafficConfig(createdTrafficConfig, c))
    : null;

  /**
   * How do we determine if a config has been configured by a user or not?
   * - If they go through the create flow, the private traffic is enabled
   * - They cannot disable private traffic by editing the config, they can
   *   only disable public traffic
   * - By default, public is enabled and private is disabled
   * - To disable private traffic they have to "delete" the config
   * - Therefore, if private traffic is enabled, then the user has configured
   *   the network config
   * - Private traffic can be disabled, but the status will still be `deleting`
   * - So we want to show all configs where private traffic is not `deleted`
   *   as that means all enabled private traffic configs and all disabled
   *   private traffic configs that are still deleting
   */
  const configuredConfigs = trafficConfigs.filter(
    c => c.status.privateTraffic !== TrafficStatus.DELETED
  );

  const existingTierRegions: Partial<Record<Tier, string[]>> = configuredConfigs.reduce(
    (acc, config) => {
      if (!(config.tier in acc)) {
        acc[config.tier] = [config.region];
      } else {
        acc[config.tier].push(config.region);
      }
      return acc;
    },
    {}
  );

  return (
    <>
      <TrafficConfigsTable
        tenant={tenant}
        configs={configuredConfigs}
        onUpdate={handleUpdateSuccess}
        onDelete={handleDeleteSuccess}
        databases={databases}
      />
      {createOpen && (
        <TrafficConfigDialog
          title="New network access configuration"
          onClose={handleClose}
          tenant={tenant}
          trafficConfig={liveCreatedTrafficConfig}
          existingTrafficConfigs={trafficConfigs}
          existingTierRegions={existingTierRegions}
          onSuccess={handleCreateSuccess}
          databases={databases}
        />
      )}
    </>
  );
};

interface PermissionDeniedProps
  extends Omit<
    React.ComponentProps<typeof Alert>,
    'type' | 'closeable' | 'onClose' | 'title' | 'description'
  > {
  title?: React.ComponentProps<typeof Alert>['title'];
  tenant: Tenant;
}

const PermissionDenied = ({
  title = 'Permission denied',
  tenant,
  ...rest
}: PermissionDeniedProps) => {
  return (
    <Alert
      type="warning"
      title={title}
      description={
        <div>
          <p>
            You must be an admin of the <strong>{tenant.name}</strong> tenant to read network access
            configurations.
          </p>
        </div>
      }
      {...rest}
    />
  );
};

const NetworkPage = () => {
  const { tenant } = useSession();

  const [createOpen, setCreateOpen] = useState(false);
  const permissions = usePermissions();

  const handleNewNetwork = () => setCreateOpen(true);
  const handleClose = () => setCreateOpen(false);

  const canCreateTrafficConfig =
    tenant.capabilities.traffic_config &&
    permissions.allow(Action.UPDATE, `namespaces/${tenant.id}/traffic-configs/tiers/*/regions/*`);

  const renderNetworkContent = () => {
    if (!tenant.capabilities.traffic_config) {
      return <FeatureDisabled tenant={tenant} />;
    }

    const canReadTrafficConfigs = permissions.allow(
      Action.READ,
      `namespaces/${tenant.id}/traffic-configs`
    );

    if (!canReadTrafficConfigs) {
      return <PermissionDenied tenant={tenant} />;
    }

    return <NetworkContent tenant={tenant} createOpen={createOpen} onCreateClose={handleClose} />;
  };

  return (
    <Page plain fullWidth>
      <div className="tw-flex tw-justify-between">
        <h4 className="tw-mb-6">Network Access</h4>
        {
          <div className="tw-flex tw-gap-4">
            <Tip>
              <Tip.Trigger>
                <Button
                  onClick={handleNewNetwork}
                  disabled={!canCreateTrafficConfig}
                  data-testid="create-traffic-config"
                >
                  New network access configuration
                </Button>
              </Tip.Trigger>
              {!canCreateTrafficConfig && (
                <Tip.Content className="tw-max-w-sm" isPortaled={false}>
                  <p>
                    You need to be an admin of the <strong>{tenant.name}</strong> tenant to create a
                    network access configuration.
                  </p>
                </Tip.Content>
              )}
            </Tip>
          </div>
        }
      </div>
      {renderNetworkContent()}
    </Page>
  );
};

const FeatureDisabled = ({ tenant }) => {
  return (
    <Alert
      type="info"
      title="Feature disabled"
      data-testid="network-access-feature-disabled-alert"
      description={
        <div>
          <p>Network access configuration is not available for tenant {tenant.name}.</p>
        </div>
      }
    />
  );
};

export default NetworkPage;
