import { Button, Dialog, Label, TextLink, Tip, Typography } from 'foundation';

import cn from 'classnames';
import React, { ReactNode, createContext, useContext, useState } from 'react';
import Icon from 'components/ui/icons';
import { useSession } from 'store';
import { TenantType, getAvailableTiers, tierDisplayName } from 'entities/tenant';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { defaults as freeDefaults } from './free-tier';

import Actions from 'actions';

import track, { useTracking } from 'react-tracking';
import { BUSINESS_CRITICAL_TIER, Tier } from 'entities/database';

type SelectInstanceCardProps = React.HTMLAttributes<HTMLDivElement>;

export const SelectInstanceCard = ({ children, className }: SelectInstanceCardProps) => {
  const classes = cn(
    'tw-border-palette-neutral-border-strong tw-flex-grow-1 tw-flex tw-min-w-52 tw-basis-[450px] tw-flex-col tw-flex-wrap tw-rounded-3xl tw-border tw-p-10',
    className
  );
  return <div className={classes}>{children}</div>;
};
export const SelectInstanceCardTitle = ({
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = cn(className, 'tw-mb-4');
  return (
    <Typography variant="subheading-large" className={classes} as="div">
      {children}
    </Typography>
  );
};

export const SelectInstanceCardHeader = ({
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = cn(className, 'tw-mb-4 tw-flex tw-min-h-[70px] tw-flex-col tw-justify-start');

  return <div className={classes}>{children}</div>;
};

export const SelectInstanceCardDescription = ({
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = cn(className, 'tw-min-h-16');
  return (
    <div className={classes}>
      <Typography variant="body-medium" as="span" className="tw-tracking-normal">
        {children}
      </Typography>
    </div>
  );
};

type ItemsContextType = {
  cardType: 'primary' | 'secondary';
};

const ItemsContext = createContext<ItemsContextType>({ cardType: 'secondary' });

const useItemsContext = () => {
  const context = useContext(ItemsContext);
  if (!context) {
    throw new Error('Accordion used without context');
  }
  return context;
};

interface SelectInstanceCardItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  cardType: 'primary' | 'secondary';
  includeMargin?: boolean;
}

export const SelectInstanceCardItems = ({
  children,
  cardType,
  className,
  includeMargin = true,
}: SelectInstanceCardItemsProps) => {
  const classes = cn(className, { '*:tw-mb-4': includeMargin });
  return (
    <ItemsContext.Provider value={{ cardType }}>
      <ul className={classes}>{children}</ul>
    </ItemsContext.Provider>
  );
};

export const SelectInstanceCardItem = ({
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { cardType } = useItemsContext();
  const classes = cn(className, 'tw-flex tw-gap-4 ', {
    'tw-text-palette-neutral-text-inverse': cardType === 'primary',
    'tw-text-palette-neutral-text-weak': cardType === 'secondary',
  });
  const iconClasses = cn('tw-h-4 tw-w-4 tw-flex-shrink-0 tw-self-center', {
    'tw-text-palette-primary-border-weak': cardType === 'primary',
    'tw-text-palette-primary-bg-status': cardType === 'secondary',
  });

  return (
    <li className={classes}>
      <Icon name="CheckIconOutline" className={iconClasses} />
      <Typography variant="body-medium" as="span" className="tw-tracking-normal">
        {children}
      </Typography>
    </li>
  );
};

export const Divider = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = cn(className, 'tw-w-full tw-border');
  return <div className={classes} />;
};

interface SelectInstanceCardButtonProps extends React.ComponentProps<typeof Button> {
  tipContent?: ReactNode;
}

export const SelectInstanceCardButton = ({
  className,
  children,
  color,
  tipContent,
  disabled = false,
  ...rest
}: SelectInstanceCardButtonProps) => {
  const classes = cn(className, 'tw-w-full tw-self-end');
  return (
    <>
      <div className="tw-grow" />
      <div className="tw-bg-palette-neutral-bg-weak tw-rounded-md">
        <Tip isDisabled={!disabled}>
          <Tip.Trigger>
            <Button
              className={classes}
              size="large"
              fill="outlined"
              color={color}
              disabled={disabled}
              {...rest}
            >
              {children}
            </Button>
          </Tip.Trigger>
          <Tip.Content isPortaled={false}>{tipContent}</Tip.Content>
        </Tip>
      </div>
    </>
  );
};

type CreateInstanceModalProps = {
  onClose: () => void;
};

const NewTierSelector = ({ onClose }: CreateInstanceModalProps) => {
  const [loading, setLoading] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();
  const tracking = useTracking();
  const session = useSession();
  const { tenant, allowFreeDatabaseCreation } = session;

  const onCreateFree = () => {
    setLoading(true);
    const data = freeDefaults(tenant.providerConfigs);

    const requestArgs = {
      Name: data.name,
      Tier: data.tier,
      Region: data.region,
      Namespace: session.currentTenant,
      CloudProvider: data.cloudProvider,
      Version: data.version,
    };

    const event = {
      tier: requestArgs.Tier,
      version: requestArgs.Version,
      region: requestArgs.Region,
    };

    tracking.trackEvent({
      action: 'create_db',
      properties: event,
    });

    Actions.databases
      .createDatabase(requestArgs)
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false);
        return defaultErrorHandler(error);
      });
  };

  const isMarketplaceTenant = [
    TenantType.N4GCP,
    TenantType.MARKETPLACE_AWS,
    TenantType.MARKETPLACE_AZURE,
  ].includes(tenant.tenantType);

  const supportedTiers: Set<Tier> = getAvailableTiers(session);

  const navigateToPro = () => {
    Actions.navigate.push({ hash: `#create-database/${Tier.PROFESSIONAL}` });
  };

  const navigateToBusinessCritical = () => {
    Actions.navigate.push({ hash: `#create-database/${BUSINESS_CRITICAL_TIER}` });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      size="unset"
      modalProps={{ className: 'tw-max-w-[1500px] !tw-overflow-y-auto' }}
    >
      <Dialog.Header>
        <div className="tw-flex tw-flex-row tw-gap-7 tw-items-center">
          New Instance
          <Typography variant="body-medium">
            <TextLink href="https://neo4j.com/pricing/" className="tw-no-underline" externalLink>
              See full comparison
            </TextLink>
          </Typography>
        </div>
      </Dialog.Header>
      <Dialog.Content>
        <div className="tw-flex tw-flex-col tw-gap-8">
          <div className="tw-flex tw-justify-between tw-gap-8">
            <SelectInstanceCard className="tw-bg-palette-neutral-bg-weak">
              <div className="tw-flex tw-flex-col tw-mb-4 tw-max-w-full" style={{ minHeight: 224 }}>
                <SelectInstanceCardTitle className="tw-text-palette-success-text">
                  AuraDB Free
                </SelectInstanceCardTitle>
                <SelectInstanceCardHeader>
                  <div className="tw-flex tw-items-baseline tw-gap-2">
                    <Typography variant="h2">$0</Typography>
                  </div>
                </SelectInstanceCardHeader>
                <SelectInstanceCardDescription className="tw-text-palette-neutral-text-weak">
                  Start with a free offer to learn and explore with graph data
                </SelectInstanceCardDescription>
                <Typography
                  variant="body-medium"
                  as="div"
                  className="tw-text-palette-neutral-text-weakest tw-flex-grow tw-content-end tw-tracking-normal"
                >
                  Auto-deleted after 30 days of inactivity
                </Typography>
              </div>
              <Divider className="tw-border-palette-neutral-border-weak" />
              <SelectInstanceCardItems cardType="secondary" className="tw-my-8">
                <SelectInstanceCardItem>No credit card required to start</SelectInstanceCardItem>
                <SelectInstanceCardItem>Access to all graph tools</SelectInstanceCardItem>
              </SelectInstanceCardItems>
              <div className="tw-grow" />
              <SelectInstanceCardButton
                color="neutral"
                onClick={onCreateFree}
                disabled={!allowFreeDatabaseCreation || isMarketplaceTenant}
                loading={loading}
                tipContent={
                  isMarketplaceTenant
                    ? 'The free tier is not available in this tenant.'
                    : 'You have reached your maximum number of free instances.'
                }
                data-testid="create-free-db"
              >
                {isMarketplaceTenant ? 'Not available' : 'Select'}
              </SelectInstanceCardButton>
            </SelectInstanceCard>
            <SelectInstanceCard className="tw-bg-palette-primary-bg-strong tw-border-none">
              <div className="tw-flex tw-flex-col tw-mb-4 tw-max-w-full" style={{ minHeight: 224 }}>
                <SelectInstanceCardTitle className="tw-text-palette-neutral-text-inverse">
                  AuraDB Professional
                </SelectInstanceCardTitle>
                <SelectInstanceCardHeader className="tw-text-palette-neutral-text-inverse">
                  <div className="tw-flex tw-items-baseline tw-gap-2">
                    <Typography variant="h2">$65</Typography>
                    <Typography variant="body-medium">/GB/month</Typography>
                  </div>
                  <div>
                    <Typography variant="body-small">Minimum 1GB instance</Typography>
                  </div>
                </SelectInstanceCardHeader>
                <SelectInstanceCardDescription className="tw-text-palette-neutral-text-inverse">
                  Build high-performance applications on a production-ready cloud database
                </SelectInstanceCardDescription>
                {tenant.availableActions.create_pro_trial.enabled && (
                  <div className="tw-flex-grow tw-content-end tw-max-w-32 md:tw-max-w-none">
                    <Label fill="semi-filled">Free Trial - No Credit Card Required</Label>
                  </div>
                )}
              </div>
              <Divider className="tw-border-palette-primary-focus" />
              <SelectInstanceCardItems cardType="primary" className="tw-my-8">
                <SelectInstanceCardItem>
                  Up to 64GB memory per database instance
                </SelectInstanceCardItem>
                <SelectInstanceCardItem>Scalable on demand</SelectInstanceCardItem>
                <SelectInstanceCardItem>Daily backups, 30-day retention</SelectInstanceCardItem>
                <SelectInstanceCardItem>
                  Available on Azure, AWS, and Google Cloud
                </SelectInstanceCardItem>
                <SelectInstanceCardItem>Advanced instance-level metrics</SelectInstanceCardItem>
              </SelectInstanceCardItems>
              <SelectInstanceCardButton
                color="primary"
                onClick={navigateToPro}
                disabled={!supportedTiers.has(Tier.PROFESSIONAL) || loading}
                tipContent="You cannot create a professional instance."
                data-testid="navigate-to-professional"
              >
                {tenant.availableActions.create_pro_trial.enabled ? 'Try for free' : 'Select'}
              </SelectInstanceCardButton>
            </SelectInstanceCard>
            {supportedTiers.has(Tier.MTE) ? (
              <SelectInstanceCard className="tw-bg-palette-neutral-bg-weak">
                <div
                  className="tw-flex tw-flex-col tw-mb-4 tw-max-w-full"
                  style={{ minHeight: 224 }}
                >
                  <SelectInstanceCardTitle className="tw-text-palette-discovery-text">
                    AuraDB {tierDisplayName(tenant, Tier.MTE)}
                  </SelectInstanceCardTitle>
                  <SelectInstanceCardHeader>
                    <div className="tw-flex tw-items-baseline tw-gap-2">
                      <Typography variant="h2">$146</Typography>
                      <Typography variant="body-medium">/GB/month</Typography>
                    </div>
                    <div>
                      <Typography variant="body-small">Minimum 2GB instance</Typography>
                    </div>
                  </SelectInstanceCardHeader>
                  <SelectInstanceCardDescription className="tw-text-palette-neutral-text-weak">
                    Scale mission-critical apps with a highly available database, granular
                    enterprise controls, and 24x7 support
                  </SelectInstanceCardDescription>
                  <Typography
                    variant="body-medium"
                    className="tw-flex-grow tw-content-end"
                    as="div"
                  >
                    <TextLink href="https://neo4j.com/contact-us/" externalLink>
                      Have credits? Contact us
                    </TextLink>
                  </Typography>
                </div>
                <Divider className="tw-border-palette-neutral-border-weak" />
                <SelectInstanceCardItems cardType="secondary" className="tw-my-8">
                  <SelectInstanceCardItem>
                    Up to 512GB memory per database instance
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    Highly available 3-zone cluster with 99.95% uptime SLA
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    Daily backups with 30-day retention and hourly point-in-time restore
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    Role-based access control with granular security
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    Pay-as-you-go and prepaid consumption billing
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    <TextLink
                      href="http://www.neo4j.com/support-terms/"
                      className="tw-no-underline"
                      externalLink
                    >
                      24x7 support
                    </TextLink>
                  </SelectInstanceCardItem>
                </SelectInstanceCardItems>
                <SelectInstanceCardButton
                  color="primary"
                  onClick={navigateToBusinessCritical}
                  disabled={loading}
                  data-testid="navigate-to-mte"
                >
                  Select
                </SelectInstanceCardButton>
              </SelectInstanceCard>
            ) : (
              <SelectInstanceCard className="tw-bg-palette-neutral-bg-weak">
                <div
                  className="tw-flex tw-flex-col tw-mb-4 tw-max-w-full"
                  style={{ minHeight: 224 }}
                >
                  <SelectInstanceCardTitle className="tw-text-palette-discovery-text">
                    {tierDisplayName(tenant, Tier.ENTERPRISE)}
                  </SelectInstanceCardTitle>
                  <SelectInstanceCardHeader>
                    <Typography variant="h2">Let&apos;s chat!</Typography>
                  </SelectInstanceCardHeader>
                  <SelectInstanceCardDescription className="tw-text-palette-neutral-text-weak">
                    For large-scale, mission-critical applications that require high availability,
                    advanced security and 24x7 Regional support.
                  </SelectInstanceCardDescription>
                </div>
                <Divider className="tw-border-palette-neutral-border-weak" />
                <SelectInstanceCardItems cardType="secondary" className="tw-my-8">
                  <SelectInstanceCardItem>Everything in AuraDB Professional</SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    Up to 512GB memory per database instance
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    Hourly backups with 30 day retention
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>
                    Dedicated Virtual Private Cloud (VPC)
                  </SelectInstanceCardItem>
                  <SelectInstanceCardItem>Private Network Connectivity</SelectInstanceCardItem>
                  <SelectInstanceCardItem>Customer Managed Keys</SelectInstanceCardItem>
                  <SelectInstanceCardItem>ISO 27001, SOC2, HIPAA</SelectInstanceCardItem>
                  <SelectInstanceCardItem>Premium 24x7 support and services</SelectInstanceCardItem>
                </SelectInstanceCardItems>
                <SelectInstanceCardButton
                  color="primary"
                  href="https://neo4j.com/contact-us/"
                  target="_blank"
                >
                  Contact Sales
                </SelectInstanceCardButton>
              </SelectInstanceCard>
            )}
          </div>
          {supportedTiers.has(Tier.MTE) && (
            <SelectInstanceCard className="tw-basis-[80px] tw-py-[30px]">
              <Typography
                variant="subheading-large"
                as="div"
                className="tw-text-palette-discovery-text"
              >
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-10">
                  AuraDB {tierDisplayName(tenant, Tier.ENTERPRISE)}
                  <Typography
                    variant="body-medium"
                    className="tw-flex tw-flex-row tw-grow tw-justify-between tw-items-baseline"
                    as="div"
                  >
                    <div className="tw-text-palette-neutral-text-weak">
                      Deploy applications, using all capabilities of AuraDB Business Critical, but
                      in a dedicated environment
                    </div>
                    <TextLink href="https://neo4j.com/pricing/" externalLink>
                      Read more
                    </TextLink>
                  </Typography>
                </div>
              </Typography>
            </SelectInstanceCard>
          )}
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default track()(NewTierSelector);
