import { FilterReportsSelection, DATE_SELECTION_OPTIONS, MAXIMUM_DAYS } from './const';
import { subHours, subDays } from 'date-fns';
import { DateRange, FilterType } from 'types/usage';

export const generateDateFilter = (
  filterDateSelection: FilterReportsSelection,
  selectedDateRange: DateRange = null
) => {
  let start: Date, end: Date, label: string;
  const now = new Date();

  switch (filterDateSelection) {
    case FilterReportsSelection.START_OF_MONTH:
      label = DATE_SELECTION_OPTIONS.find(k => k.value === FilterReportsSelection.START_OF_MONTH)
        .label;
      start = new Date(now.getFullYear(), now.getMonth(), 1);
      end = null;
      break;
    case FilterReportsSelection.LAST_24_HOURS:
      label = DATE_SELECTION_OPTIONS.find(k => k.value === FilterReportsSelection.LAST_24_HOURS)
        .label;
      start = subHours(now, 24);
      end = null;
      break;
    case FilterReportsSelection.LAST_7_DAYS:
      label = DATE_SELECTION_OPTIONS.find(k => k.value === FilterReportsSelection.LAST_7_DAYS)
        .label;
      start = subDays(now, 7);
      end = null;
      break;
    case FilterReportsSelection.LAST_30_DAYS:
      label = DATE_SELECTION_OPTIONS.find(k => k.value === FilterReportsSelection.LAST_30_DAYS)
        .label;
      start = subDays(now, 30);
      end = null;
      break;
    case FilterReportsSelection.LAST_90_DAYS:
      label = DATE_SELECTION_OPTIONS.find(k => k.value === FilterReportsSelection.LAST_90_DAYS)
        .label;
      start = subDays(now, 90);
      end = null;
      break;
    case FilterReportsSelection.LAST_YEAR:
      label = DATE_SELECTION_OPTIONS.find(k => k.value === FilterReportsSelection.LAST_YEAR).label;
      start = subDays(now, MAXIMUM_DAYS);
      end = null;
      break;
    case FilterReportsSelection.CUSTOM_RANGE:
      if (!selectedDateRange) return null;
      start = new Date(selectedDateRange[0]);
      end = new Date(selectedDateRange[1]);
      label = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
      break;
    default:
      return null;
  }

  return { label, value: filterDateSelection, start, end };
};

export const getMaximumDateRange = (max_days: number) => {
  const now = new Date();
  return [subDays(now, max_days), now] as DateRange;
};

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateDefaultDateFilter = () => {
  const rangeDate = generateDateFilter(FilterReportsSelection.START_OF_MONTH);
  return [{ ...rangeDate, type: FilterType.DATE }];
};
