import React from 'react';
import { Link, LoadingSpinner, Page } from 'components/foundation';
import Icon from 'components/ui/icons';
import LoadError from 'components/application/load-error';
import SsoConfigTable from './sso-config-table';
import { useInternalSsoConfigQuery } from 'remote/resources/internal-sso-configs';
import { useOrganizationQuery } from 'remote/resources/organizations';

export const SsoConfigDetailsPage = ({ ssoConfigId }: { ssoConfigId: string }) => {
  const ssoConfigQuery = useInternalSsoConfigQuery(ssoConfigId);
  const organizationQuery = useOrganizationQuery(ssoConfigQuery.data?.organizationId);
  const orgAuth0Id = organizationQuery.data?.ssoOrgId;

  const handleRefreshSsoConfig = () => {
    ssoConfigQuery.refetch();
    organizationQuery.refetch();
  };

  if (ssoConfigQuery.isLoading || organizationQuery.isLoading) {
    return <LoadingSpinner size="large" expand minHeight={300} />;
  }

  if (ssoConfigQuery.isError || organizationQuery.isError) {
    return <LoadError />;
  }

  const ssoConfig = ssoConfigQuery.data;

  return (
    <Page fullWidth>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <Link href={`#admin/organizations/${ssoConfig.organizationId}`}>
          <strong className="tw-text-xs tw-flex">
            <Icon name="ChevronLeftIconOutline" className="tw-h-3 tw-w-3 tw-mr-1 tw-self-center" />
            To Organization {ssoConfig.organizationId}
          </strong>
        </Link>
        <SsoConfigTable
          config={ssoConfig}
          orgAuth0Id={orgAuth0Id}
          onRefreshSsoConfig={handleRefreshSsoConfig}
        />
      </div>
    </Page>
  );
};
