import { Database } from 'entities/database';
import { GdsSession } from 'types/gds-session';
import { formatDistance } from 'date-fns';
import { TenantUser } from 'types/user';
import add from 'date-fns/add';
import isBefore from 'date-fns/isBefore';

// need user email and resource usage
export interface GdsSessionTableProps {
  sessionName: string;
  sessionId: string;
  instanceId: string;
  userEmail: string;
  instanceName: string;
  memory: string;
  timeRemaining: string;
  delete?: React.ReactElement;
}

const getTimeRemaining = (expiryDate: Date, ttl: string) => {
  const parseDuration = (duration: string) => {
    const regex = /(?:(\d+)h)?(?:(\d+)m)?(?:(\d+\.?\d*)s)?/;
    const match = duration.match(regex);
    const hours = match[1] ? parseInt(match[1], 10) : 0;
    const minutes = match[2] ? parseInt(match[2], 10) : 0;
    const seconds = match[3] ? parseFloat(match[3]) : 0;
    return { hours, minutes, seconds };
  };

  const currentDate = new Date();
  const ttlDate = add(currentDate, parseDuration(ttl));
  const earlierDate = isBefore(ttlDate, expiryDate) ? ttlDate : expiryDate;
  return formatDistance(currentDate, earlierDate);
};

export const createGdsTableRows = (
  gdsSessions: GdsSession[],
  databases: Database[],
  users: TenantUser[]
): GdsSessionTableProps[] => {
  const usersLookup = {};
  users.forEach(user => {
    usersLookup[user.UserId.toLowerCase()] = user;
  });

  return gdsSessions.map(gs => {
    const associatedDB = databases.find(db => db.DbId === gs.dbId);

    return {
      sessionName: gs.sessionName,
      sessionId: gs.sessionId,
      instanceId: gs.dbId,
      userEmail: usersLookup[gs.userId.toLowerCase()].Email || gs.userId, // uuid better than nothing
      instanceName: associatedDB?.Name || 'N/A',
      memory: gs.memory,
      timeRemaining: getTimeRemaining(new Date(gs.expiryDate), gs.ttl),
    };
  });
};
