import React, { useState } from 'react';
import { Alert, Button, Dialog, Form, FormInput, FormSelect, Switch } from 'foundation';
import { BillingMethod, InternalTenant, PlanType, TenantProfile } from 'entities/tenant';
import InternalTenantResource, {
  InternalEditTenantPayload,
} from 'remote/resources/internal-tenant';
import './edit-tenant.css';
import { tenantProfileOptions } from '../../constants';

interface Props {
  tenant: InternalTenant;
  open: boolean;
  onClose: () => void;
  onSuccess: (updatedTenant: InternalTenant) => void;
}

export const EditTenantModal = ({ open, onClose, onSuccess, tenant }: Props) => {
  const [data, setData] = useState<InternalEditTenantPayload>({
    friendlyName: tenant.friendlyName,
    salesforceCppId: tenant.salesforceCppId,
    tenantProfile: tenant.tenantProfile,
    billingMethod: tenant.billingMethod,
  });
  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState(false);

  const tenantProfileOptionsMap = tenantProfileOptions.map(option => ({ ...option }));

  const handleFriendlyNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setData({ ...data, friendlyName: e.target.value });
  };

  const handleSalesforceCppIdChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setData({ ...data, salesforceCppId: e.target.value });
  };

  const handleTenantProfileChange = ({ value }) => {
    setData({ ...data, tenantProfile: value });
  };

  const handleTenantBillingMethodChange = (checked: Boolean) => {
    setData({ ...data, billingMethod: checked ? BillingMethod.PREPAID : BillingMethod.PAYG });
  };

  const handleSubmit = async () => {
    if (
      data.billingMethod === BillingMethod.PREPAID &&
      !data.salesforceCppId &&
      data.tenantProfile !== TenantProfile.INTERNAL
    ) {
      setError('Salesforce Customer Project Profile Id is required to enable Prepaid Billing.');
      return;
    }
    setLoading(true);
    try {
      const updatedTenant = await InternalTenantResource.editTenant(tenant.id, {
        friendlyName: data.friendlyName,
        salesforceCppId: data.salesforceCppId || null,
        tenantProfile: data.tenantProfile,
        billingMethod: data.billingMethod,
      });
      onSuccess(updatedTenant);
    } catch (e) {
      if (e.reason === 'single-tenancy-isolation-unit-occupied') {
        // This message says "Single tenancy isolation unit {id} is occupied"
        setError(e.responseMessage);
      } else if (e.reason === 'salesforce-cpp-does-not-exist') {
        setError(`Salesforce Customer Project Profile ${data.salesforceCppId} does not exist.`);
      } else {
        setError(String(e));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>Edit Tenant {tenant.id}</Dialog.Header>
      <Form onSubmit={handleSubmit}>
        <Dialog.Content className="tw-flex tw-flex-col tw-gap-2">
          {error && <Alert type="danger">{error}</Alert>}
          <FormInput
            fluid
            data-testid="edit-friendly-name-input"
            value={data.friendlyName}
            onChange={handleFriendlyNameChange}
            label="Friendly Name"
            placeholder="Friendly Name"
            disabled={loading}
          />
          <FormInput
            fluid
            data-testid="edit-salesforce-cpp-id-input"
            value={data.salesforceCppId}
            onChange={handleSalesforceCppIdChange}
            label="Salesforce Customer Project Profile Id"
            disabled={loading}
            helpText="Note that updating this property will NOT apply any changes to users that are already members of this tenant."
          />
          <FormSelect
            data-testid="tenant-profile"
            fluid
            options={tenantProfileOptionsMap}
            value={data.tenantProfile}
            onChange={handleTenantProfileChange}
            label="Tenant Profile"
            helpText="External tenant instances will be created with a high priority, while Internal instances will have a lower priority and features enabled for internal users 
                    will be available. Select External for external customer tenants and Internal for internal employee tenants."
            defaultValue={null}
            isClearable={true}
            errorText={null}
            aria-label="Tenant Profile"
          />
          {tenant.planType === PlanType.SELF_SERVE && (
            <div className="tw-pt-1">
              <Switch
                data-testid="tenant-billing-method-switch"
                label="Enable Prepaid Billing"
                aria-label="Enable Prepaid Billing"
                checked={data.billingMethod === BillingMethod.PREPAID}
                onChange={event => handleTenantBillingMethodChange(event.target.checked)}
              />
              <Alert
                type="warning"
                className="tw-mt-2 tw-p-1 n-body-small tw-text-palette-neutral-text-weaker"
              >
                Please avoid toggling this flag on the 1st of any month to avoid billing conflicts
                or consult Aura Console team if you’re unsure.
              </Alert>
            </div>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button type="submit" data-testid="edit-tenant-update-button">
            Update
          </Button>
        </Dialog.Actions>
      </Form>
    </Dialog>
  );
};
