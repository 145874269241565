import { saveAs } from 'file-saver';
import { TransformedBreakdownItem, TransformedUsage } from 'types/usage';

/**
 * Converts an Array of Objects to a CSV string.
 *
 * @param {T[]} objectArray - Array of generic objects to be converted.
 */
const objectArrayToCSV = <T>(objectArray: T[]): string => {
  const csvHeader = Object.keys(objectArray[0]).join(',') + '\n';
  const csvContent = objectArray.map(e => Object.values(e).join(',')).join('\n');
  return csvHeader + csvContent;
};

const downloadUsage = (usage: TransformedUsage) => {
  let startDateISO = '';
  let endDateISO = '';

  if (usage.usagePeriodStart && usage.usagePeriodEnd) {
    startDateISO = '_' + new Date(usage.usagePeriodStart).toISOString();
    endDateISO = '_' + new Date(usage.usagePeriodEnd).toISOString();
  }
  const fileName = `usage${startDateISO}${endDateISO}.csv`;
  const csv = objectArrayToCSV<TransformedBreakdownItem>(usage.breakdown);
  const blob = new Blob(['\uFEFF' + csv], { type: 'data:application/csv;charset=utf-8' });
  saveAs(blob, fileName);
};

export default downloadUsage;
