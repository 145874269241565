import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import { calcMonthlyCost } from 'application/db-sizes/capacities';
import formatDollars from 'utils/format-dollars';
import {
  DatabaseSize,
  Tier,
  isSizeAvailableInRegion,
  CloudProvider,
  friendlyCloudProviderNameMap,
  findDatabaseSize,
  isSizeAvailableForVersion,
  DATABASE_NAME_CHARACTER_LIMIT,
} from 'entities/database';
import CreateFormSizePicker from './form-size-picker';
import { validateYup, Validation } from 'utils/validation';
import { CreateDatabaseFormData, CreateDatabaseFormOptions } from './form-data';
import { DatabaseNameInput, RegionSelect, VersionSelect } from './form-essentials';
import { useNeo4jVersionOptions, useRegionOptions } from './hooks';
import { PartnerFormInput } from 'application/partner/partner';
import {
  TenantType,
  availableRegionsForCloudProviderAndTier,
  getAvailableCloudProviders,
  tierDisplayName,
} from 'entities/tenant';
import { useDatabaseState, useSession } from 'store';
import { Alert, Checkbox, Accordion, TextLink, Switch, Typography, Tip } from 'foundation';
import './form-self-serve.css';
import classNames from 'classnames';
import track, { useTracking } from 'react-tracking';
import { useDarkTheme } from 'utils/hooks';
import { CloudProviderCard } from './cloud-provider-card';
import { getDefaultSelfServeDatabaseName } from 'components/utils';
import { getDefaultVersion } from 'utils/neo4j-versions';

export const defaults = () => {
  return {
    name: getDefaultSelfServeDatabaseName(),
    tier: Tier.PROFESSIONAL,
    version: undefined,
    region: undefined,
    size: undefined,
    confirmed: undefined,
    cloudProvider: undefined,
    isTrial: false,
  };
};

const getDefaultSize = (options: CreateDatabaseFormOptions) => {
  const preferredDefaultSize = options.tenant.requiresBilling ? '8GB' : '1GB';

  const availableSizes = options.databaseSizes[Tier.PROFESSIONAL];
  const exactMatch = findDatabaseSize(availableSizes, preferredDefaultSize);

  return exactMatch || availableSizes[0];
};

export const schema = yup.object({
  cloudProvider: yup
    .string()
    .required()
    .label('Cloud Provider'),
  tier: yup.string().required(),
  name: DatabaseNameInput.yupValidator.label('Name'),
  region: yup
    .string()
    .required()
    .label('Region'),
  version: yup
    .string()
    .required()
    .label('Version'),
  size: yup.object({ memory: yup.string().required() }).required(),
  confirmed: yup
    .bool()
    .required('You must check that your understand the costs of creating a database')
    .isTrue('You must check that your understand the costs of creating a database'),
});

export const validate = (
  data: CreateDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => {
  return validateYup(schema, data, onlyRequired || false);
};

interface Props {
  data: CreateDatabaseFormData;
  options: CreateDatabaseFormOptions;
  validation: Validation<CreateDatabaseFormData>;
  onChange: (data: CreateDatabaseFormData, submit?: boolean) => any;
  disabled?: boolean;
}

const ProfessionalForm = ({ data, options, onChange, validation, disabled }: Props) => {
  const session = useSession();
  const tracking = useTracking();
  const neo4jVersionOptions = useNeo4jVersionOptions(options.neo4jVersions);
  const cloudProviders = getAvailableCloudProviders(
    options.providerConfigs,
    options.tenant.tenantType
  );

  const regions = useMemo(() => {
    return data.cloudProvider
      ? availableRegionsForCloudProviderAndTier(
          options.providerConfigs,
          data.cloudProvider,
          data.tier
        )
      : [];
  }, [data.cloudProvider]);
  const regionOptions = useRegionOptions(regions);
  const [openProvider, setOpenProvider] = useState(true);
  const [openSize, setOpenSize] = useState(!!data.region);
  const [openDetails, setOpenDetails] = useState(!!data.size);
  const [showProTrialOptions, setShowProTrialOptions] = useState(false);
  const isDarkTheme = useDarkTheme();
  const [showVersionWarning, setShowVersionWarning] = useState(false);
  const { databases } = useDatabaseState();

  const handleSizeChange = useCallback((size: DatabaseSize) => onChange({ ...data, size }), [data]);
  const handleConfirmedChange = ({ target: { checked } }) => {
    onChange({ ...data, confirmed: checked });
  };
  const handleVersionChange = (version: string) => {
    if (!data.size) {
      onChange({ ...data, version });
    } else {
      const selectedSizeAllowed = isSizeAvailableForVersion(data.size, version, session.tenant);

      if (selectedSizeAllowed) {
        onChange({ ...data, version });
      } else {
        onChange({ ...data, version, size: undefined });
      }
    }
  };
  const handleDatabaseNameChange = (name: string) => onChange({ ...data, name });
  const handleRegionChange = (region: string) => {
    if (!data.size) {
      onChange({ ...data, region });
    } else {
      const selectedSizeAllowed = isSizeAvailableInRegion(data.size, region, data.cloudProvider);

      if (selectedSizeAllowed) {
        onChange({ ...data, region });
      } else {
        onChange({ ...data, region, size: undefined });
      }
    }
  };

  const handleSelectCloudProvider = (event?: React.MouseEvent, cloudProvider?: CloudProvider) => {
    event?.preventDefault();

    if (cloudProviders.length === 1) return false;

    if (!cloudProvider || data.cloudProvider === cloudProvider) {
      onChange({
        ...data,
        version: undefined,
        cloudProvider: undefined,
        size: undefined,
        region: undefined,
        confirmed: false,
      });
      return false;
    }

    onChange({
      ...data,
      cloudProvider,
      region: availableRegionsForCloudProviderAndTier(
        options.providerConfigs,
        cloudProvider,
        data.tier
      )[0]?.name,
      version: getDefaultVersion(options.providerConfigs, cloudProvider, Tier.PROFESSIONAL),
      size: undefined,
    });
  };

  useEffect(() => {
    setOpenSize(!!data.cloudProvider || !!validation?.size?.message);
  }, [data.cloudProvider, validation?.size]);

  useEffect(() => {
    setOpenDetails(!!data.size || !!validation?.name?.message || !!data.version);
  }, [data.size, data.version, validation?.name]);

  useEffect(() => {
    if (cloudProviders.length === 1) {
      onChange({
        ...data,
        cloudProvider: cloudProviders[0],
        region: availableRegionsForCloudProviderAndTier(
          options.providerConfigs,
          cloudProviders[0],
          data.tier
        )[0]?.name,
        size: getDefaultSize(options),
        version: getDefaultVersion(options.providerConfigs, cloudProviders[0], Tier.PROFESSIONAL),
      });
    }
  }, []);

  const handleShowProTrialOptionsChange = (checked: boolean) => {
    setShowProTrialOptions(checked);
    tracking.trackEvent({
      action: checked ? 'AURA_PRO_TRIAL_TOGGLE' : 'AURA_PRO_TRIAL_UNTOGGLE',
    });
    onChange({ ...data, size: undefined });
  };

  const costPerHour = data.size?.cost_per_hour || '0';
  const costPerMonth = calcMonthlyCost(costPerHour);
  const isN4GCPTenant = options.tenant.tenantType === TenantType.N4GCP;
  const isMarketplaceTenant = [TenantType.MARKETPLACE_AWS, TenantType.MARKETPLACE_AZURE].includes(
    options.tenant.tenantType
  );
  const showRegionalSupportTerms = [Tier.MTE, Tier.ENTERPRISE].includes(data?.tier);

  useEffect(() => {
    const db = databases.some(database => database.DesiredSettings.Version === '4');
    setShowVersionWarning(db && data.version === '5');
  }, [databases, data.version]);
  const regionPlaceholder = data.cloudProvider
    ? !regions.length
      ? 'There are currently no available regions...'
      : 'Select...'
    : 'Select a cloud provider to see available regions...';

  const filteredSizeOptions = useMemo(() => {
    if (session.tenant.availableActions.create_pro_trial.enabled && showProTrialOptions) {
      return options.databaseSizes[data.tier].filter(size => size.is_trial);
    }
    return options.databaseSizes[data.tier].filter(size => !size.is_trial);
  }, [
    options.databaseSizes,
    data.tier,
    session.tenant.availableActions.create_pro_trial,
    showProTrialOptions,
  ]);

  return (
    <>
      {!isN4GCPTenant &&
        !isMarketplaceTenant &&
        !session.allowFreeDatabaseCreation &&
        !options.isUpgrade && (
          <div>
            <Alert
              description={`You are now creating a paid AuraDB ${tierDisplayName(
                session.tenant,
                data.tier
              )} instance. You get 1 AuraDB Free instance, which you already have running.`}
              className="tw-mb-6"
            />
          </div>
        )}
      <div className="tw-flex tw-flex-col tw-gap-6">
        <Accordion
          header={
            <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
              Cloud provider and region
              {data.cloudProvider && (
                <div className="tw-flex n-body-medium tw-text-palette-neutral-text-weaker tw-gap-2 tw-items-center tw-mr-2">
                  {data.region}, {friendlyCloudProviderNameMap[data.cloudProvider]}
                </div>
              )}
            </div>
          }
          open={openProvider}
          onOpen={() => setOpenProvider(!openProvider)}
          position="left"
          data-testid="accordion-cloud-provider"
        >
          <div className="tw-flex tw-flex-col tw-gap-6">
            <div className="n-subheading-medium tw-text-palette-neutral-text-weaker">Provider</div>
            <div className="tw-flex tw-flex-row tw-gap-6" role="radiogroup">
              {cloudProviders.includes(CloudProvider.GCP) && (
                <CloudProviderCard
                  providerIcon="gcp"
                  selected={data.cloudProvider === CloudProvider.GCP}
                  onSelect={e => handleSelectCloudProvider(e, CloudProvider.GCP)}
                  data-testid="cloud-provider-gcp-card"
                  aria-label="GCP"
                />
              )}
              {cloudProviders.includes(CloudProvider.AWS) && (
                <CloudProviderCard
                  providerIcon={isDarkTheme ? 'aws-dark' : 'aws'}
                  selected={data.cloudProvider === CloudProvider.AWS}
                  onSelect={e => handleSelectCloudProvider(e, CloudProvider.AWS)}
                  data-testid="cloud-provider-aws-card"
                  aria-label="AWS"
                />
              )}
              {cloudProviders.includes(CloudProvider.AZURE) && (
                <CloudProviderCard
                  providerIcon="azure"
                  selected={data.cloudProvider === CloudProvider.AZURE}
                  onSelect={e => handleSelectCloudProvider(e, CloudProvider.AZURE)}
                  data-testid="cloud-provider-azure-card"
                  aria-label="Azure"
                />
              )}
            </div>
            <div className="region">
              <RegionSelect
                placeholder={regionPlaceholder}
                value={data.region}
                options={regionOptions}
                onChange={handleRegionChange}
                cloudProvider={data.cloudProvider}
                validationError={validation?.region?.message}
                styles={{
                  input: provided => ({
                    ...provided,
                    visibility: openProvider ? 'visible' : 'hidden',
                    color: 'rgb(var(--theme-palette-neutral-text-default))',
                    lineHeight: '24px',
                  }),
                }}
              />
              <label className="n-body-small tw-text-palette-neutral-text-weak tw-inline-block tw-mt-2">
                Need a different region?{' '}
                <TextLink href="https://aura.feedback.neo4j.com/auradb-regions" externalLink>
                  Let us know!
                </TextLink>
              </label>
            </div>
          </div>
        </Accordion>
        <Accordion
          position="left"
          header={
            <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
              Instance size
              {data.size && (
                <div className="n-body-medium tw-text-palette-neutral-text-weaker tw-mr-2">
                  {data.size.memory}
                </div>
              )}
            </div>
          }
          open={openSize}
          onOpen={() => setOpenSize(!openSize)}
          disabled={regions.length === 0}
          data-testid="accordion-size-picker"
        >
          <div className="tw-flex tw-flex-col tw-gap-4">
            {session.tenant.availableActions.create_pro_trial.enabled &&
              data.tier === Tier.PROFESSIONAL && (
                <Tip isDisabled={data.cloudProvider === CloudProvider.GCP}>
                  <Tip.Trigger>
                    <Switch
                      disabled={data.cloudProvider !== CloudProvider.GCP}
                      checked={showProTrialOptions}
                      onChange={event => handleShowProTrialOptionsChange(event.target.checked)}
                      label="Show trial offers"
                    />
                  </Tip.Trigger>
                  <Tip.Content isPortaled={false}>Trials are only available on GCP</Tip.Content>
                </Tip>
              )}
            {regions.length > 0 && (
              <CreateFormSizePicker
                value={data.size}
                options={filteredSizeOptions}
                selectedRegion={data.region}
                cloudProvider={data.cloudProvider}
                errorMessage={validation?.size?.message}
                onChange={handleSizeChange}
                selectedVersion={data.version}
                hideTitle
              />
            )}
          </div>
        </Accordion>
        <Accordion
          position="left"
          header={
            <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
              Instance details
              {data.name && data.size && (
                <div className="tw-flex tw-flex-row n-body-medium tw-text-palette-neutral-text-weaker tw-mr-2">
                  <div className="tw-truncate" style={{ maxWidth: 200 }}>
                    {data.name}
                  </div>
                  , v{data.version}
                </div>
              )}
            </div>
          }
          open={openDetails}
          onOpen={() => setOpenDetails(!openDetails)}
          data-testid="accordion-details"
          disabled={!data.version}
        >
          <div
            className={classNames('form-self-serve instance-details tw-mt-4 tw-mb-6', {
              'with-partner-account': isN4GCPTenant,
            })}
            data-testid="create-professional-db"
          >
            <div className="name">
              <DatabaseNameInput
                value={data.name || ''}
                onChange={handleDatabaseNameChange}
                validationError={
                  validation?.name?.message ||
                  ((data.name ?? '').length > DATABASE_NAME_CHARACTER_LIMIT
                    ? `Name must be at most ${DATABASE_NAME_CHARACTER_LIMIT} characters`
                    : null)
                }
              />
            </div>
            <div className="version">
              <VersionSelect
                value={data.version}
                options={neo4jVersionOptions}
                onChange={handleVersionChange}
                validationError={validation?.version?.message}
              />
            </div>

            {isN4GCPTenant && (
              <div className="partner">
                <PartnerFormInput googleProjectId={options.tenant.googleProjectId} />
              </div>
            )}
          </div>
        </Accordion>
      </div>
      {showVersionWarning && (
        <Alert
          type="warning"
          icon={true}
          description="The default Neo4j Version is now 5. Please check this is the desired version before continuing."
          className="tw-mb-6"
        />
      )}
      {data.size && (
        <div>
          {!data.size?.is_trial ? (
            <Alert>
              <div>
                <Typography variant="body-large">
                  This instance costs {formatDollars(data.size.cost_per_hour)} per hour. Continuous
                  running for 1 month will cost {formatDollars(costPerMonth, 2)} based on a 730 hour
                  average month.
                </Typography>
              </div>
              {showRegionalSupportTerms && (
                <Typography variant="body-large">
                  <div className="tw-mt-4">
                    During the Subscription Term, Neo4j will provide Support Services - Support
                    Level &quot;Regional&quot; - according to current terms at{' '}
                    <TextLink href="http://www.neo4j.com/support-terms/" externalLink>
                      Neo4j Product &amp; Support Terms
                    </TextLink>{' '}
                    and Services availability according to the{' '}
                    <TextLink href="https://neo4j.com/terms/sla/aurabc/" externalLink>
                      Neo4j Aura Service Level
                    </TextLink>
                    .
                  </div>
                  <div className="tw-mt-4">
                    Neo4j may modify Support Services and availability Service Levels provided no
                    such modification shall result in a material reduction in Support Services or
                    Services availability during the Subscription Term.
                  </div>
                </Typography>
              )}
            </Alert>
          ) : (
            <Alert>
              Your 7 day trial of Aura Professional will begin after you create an instance.
            </Alert>
          )}
          <div className="tw-mt-4">
            <Checkbox
              label="I accept"
              checked={data.confirmed || false}
              onChange={handleConfirmedChange}
              disabled={disabled}
              data-testid="pricing-confirmation-check"
              key="confirm-checkbox"
            />
            {validation?.confirmed?.message && (
              <Alert type="danger" className="tw-mt-2">
                {validation?.confirmed?.message}
              </Alert>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default track()(ProfessionalForm);
